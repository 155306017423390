$xs: 'screen and (max-width: 599px)';
$sm: 'screen and (min-width: 600px) and (max-width: 959px)';
$md: 'screen and (min-width: 960px) and (max-width: 1279px)';
$lg: 'screen and (min-width: 1280px) and (max-width: 1919px)';
$xl: 'screen and (min-width: 1920px) and (max-width: 5000px)';
$lt-sm: 'screen and (max-width: 599px)';
$lt-md: 'screen and (max-width: 959px)';
$lt-lg: 'screen and (max-width: 1279px)';
$lt-xl: 'screen and (max-width: 1919px)';
// **********************************************
@media #{$lt-lg} {
    .img-secteur {
        width: 100% !important;
        height: 42pc !important;
    }
    .logo-nav {
        // height: 70px;
        height: 92px !important;
        width: 238px !important;
    }
}

@media #{$lt-md} {
    .tabset-content {
        .nav-tabs {
            overflow-y: auto;
            flex-wrap: nowrap;
        }
    }
    .menu-mobile {
        position: absolute;
        height: 140px;
        width: 350px;
        top: 49px;
        padding: 10px;
        background: $col-primary;
    }
    .btn-mobile {
        display: unset !important;
    }
}

@media #{$xs} {
    .img-secteur {
        /*height: 28pc !important;
        height: 39pc !important;*/
        height: 406.1px !important;
        width: 100% !important;
    }
    .image-pub {
        height: unset !important;
    }
}

@media (min-width: 576px) {
    .tabset-content {
        .tab-content {
            padding: 10px calc(50% - 270px);
        }
        .nav {
            margin: 0 calc(50% - 270px);
        }
    }
}

@media (min-width: 768px) {
    .tabset-content {
        .tab-content {
            padding: 10px calc(50% - 360px);
        }
        .nav {
            margin: 0 calc(50% - 360px);
        }
    }
}

@media (max-width: 768px) {
    .content-menu-site {
        height: 85px !important;
        .btn-icon {
            img {
                height: 25px;
            }
        }
        img,
        .btn-menu img {
            height: 50px;
        }
        .container {
            max-width: 100% !important;
        }
    }
}

@media (min-width: 992px) {
    .card-offre {
        top: -100px !important;
    }
    .tabset-content {
        .tab-content {
            padding: 10px calc(50% - 480px);
        }
        .nav {
            margin: 0 calc(50% - 480px);
        }
    }
}

@media (min-width: 1200px) {
    .tabset-content {
        .tab-content {
            padding: 10px calc(50% - 570px);
        }
        .nav {
            margin: 0 calc(50% - 570px);
        }
    }
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1060px;
    }
}

@media (min-width: 1400px) {
    .tabset-content {
        .tab-content {
            padding: 10px calc(50% - 660px);
        }
        .nav {
            margin: 0 calc(50% - 660px);
        }
    }
}

@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        /*max-width: 1127px !important;*/
        max-width: 1300px !important;
    }
}