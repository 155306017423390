ng-select.bootstrap {
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100%;
  border: 1px solid #4ab3cc !important;
  height: 36px;
  width: 366px;
  border-radius: 0;

  * {
    box-sizing: border-box;
  }

  // Container.
  >div {
    display: block;
    width: 100%;
    background-image: none;
    background-clip: padding-box;



    &.below {
      -webkit-appearance: none;
      background: url(/assets/iconselect.svg) no-repeat right center;
      background-size: 26px;
      cursor: pointer;
      border: 0px;
      margin-top: 5px;
      width: 366px !important;
      left: 0 !important;
      margin-left: -14px;
    }

    &.disabled {
      background-color: transparent;
      background: none;
    }
    &.disaled {
      background-color: transparent;
      background: none;
    }
    >div.single {
      display: flex;
      height: 34px;
      width: 100%;

      >div.value,
      >div.placeholder {
        flex: 0.25;
        line-height: 34px;
        overflow: hidden;
        padding: 0 10px;
        white-space: nowrap;
      }

      >div.placeholder {
        color: #757575;
        margin-top: -2px;
      }

      >div.clear,
      >div.toggle {
        -webkit-appearance: none;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
       /* background: url(../img/Shape.png)93% 50%;*/
        background-repeat: no-repeat;
        border-radius: .25rem;

        &:hover {
          background-color: #ececec;
        }
      }

      >div.clear {
        font-size: 18px;
      }

      >div.toggle {
        font-size: 14px;
        display: none;
      }
    }

    >div.multiple {
      display: flex;
      flex-flow: row wrap;
      height: 100%;
      min-height: 34px;
      padding: 0;
      width: 100%;
      margin-top: -11px;

      >div.option {
        background-color: #4ab3cc;
        border: 1px solid darken(#f2f9fc, 10%);
        color: #fff;
        display: inline-block;
        font-size: .9em;
        margin: 0;
        padding: 0 5px 0 0;
        margin-left: 5px;
        margin-top: 5px;
        vertical-align: top;
        height: 24px;

        span.deselect-option {
          display: inline-block;
          color: #fff;
          cursor: pointer;
          font-size: .9em;
          height: 22px;
          line-height: 22px;
          border-right: 1px solid darken(#f2f9fc, 10%);
          padding: 0 5px;
          margin-right: 5px;

          &:hover {
            color: #4ab3cc;
            background-color: darken(#f2f9fc, 5%);
          }
        }
      }

      .selected.highlighted {
        background-color: #4ab3cc !important;
        color: #fff;
      }

      input {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 34px;
        line-height: 34px;
        padding: 0;

        &:focus {
          outline: none;
        }

        &::placeholder {
          flex: 1;
          line-height: 34px;
          overflow: hidden;
          padding: 0 10px;
          white-space: nowrap;
        }
      }
    }

    >div.multiple:focus {
      outline: none;
    }
  }

  // Label.
  label {
    color: rgba(0, 0, 0, 0.38);
    display: block;
    font-size: 13px;
    padding: 4px 0;
  }
}

.M_loginS .toggle {
  display: none;
}

.M_loginS .clear {
  display: none;
}

.M_loginS ng-select>div {
  border: none !important;
  outline: none !important;
  padding-top: 5px;
}

.M_loginS .below {
  top: 38px;
  left: 0px;
  // width: 253px;
}


.M_coordonnees .toggle {
  display: none;
}

.M_coordonnees .clear {
  display: none;
}

.M_coordonnees ng-select>div {
  border: none !important;
  outline: none !important;
  padding-top: 5px;
}

.M_coordonnees .below  {
  top: 38px !important;
  left: -1px !important;
  width: 229px !important;
  // -webkit-appearance: none;
  // -moz-border-radius: 0;
  // -webkit-border-radius: 0;
  // background: url(../img/Shape.png)93% 50%;
  // background-repeat: no-repeat;
  // border-radius: 0rem;
}

.M_coordonnees   {
  -webkit-appearance: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  background: url(../img/Shape.png)93% 50%;
  background-repeat: no-repeat;
  border-radius: 0rem;
}
/* Cacher la flèche par défaut du select */
.M_selectLogin  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px; /* Ajoute un espace à droite pour la flèche personnalisée */
}

/* Ajouter une flèche personnalisée à droite du select */
.M_selectLogin ::after {
  content: '⌵'; /* Utilisez le caractère de flèche de votre choix */
  position: absolute;
  top: 50%;
  right: 15px; /* Ajustez la position horizontale de la flèche selon vos besoins */
  transform: translateY(-50%);
  pointer-events: none; /* Assurez-vous que la flèche ne soit pas cliquable */
  color: #757575; /* Couleur de la flèche */
}
ng-select>div>div.single>div.placeholder {
  color: #757575;
  background-color: #ffff;
}
