.modal-backdrop {
  background-color: #5c5656 !important;
}
.img-bg-dialog {
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
}
.img-bg-login {
  background-image: url("../images/img-popin-form.png");
}
.img-bg-welcome {
  background-image: url("../images/img-carte.png");
}
.img-bg-password {
  background-image: url("../images/bg-password.jpg");
}
.img-bg-secteur-1 {
  background-image: url("../images/section-1.png");
}
.img-bg-secteur-2 {
  background-image: url("../images/section-2.png");
}
.modal.show .modal-dialog {
  display: flex;
  align-items: center;
  height: 100vh;
}
