p {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}

.title-secteur {
    font-family: 'PoetsenOne', sans-serif;
    font-weight: 100;
    font-size: 40px !important;
    line-height: 48px;
    letter-spacing: 0px;
    opacity: 1;
}

.sous-title-secteur {
    font-family: 'PoetsenOne', sans-serif;
    font-weight: 100;
    font-size: 20px !important;
    line-height: 48px;
    letter-spacing: 0px;
}

.color-primary {
    color: $col-primary;
}
.color-primary-pre {
    color: $col-primary-pre;
}
.color-secondary {
    color: $col-secondary;
}

.bg-col-primary {
    background: $col-primary;
    color: $col-w;
}

.bg-col-secondary {
    background: $col-secondary;
    color: $col-w;
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
}

.bold500 {
    font: normal normal 600 12px/25px Poppins;
    letter-spacing: 0px;
    opacity: 1;
}

@media (min-width: 400px) and (max-width: 900px) {
    .title-secteur {
        font-size: 14px !important;
        text-align: justify;
    }
}

@media screen and (max-width: 599px) {
    .title-secteur {
        font-size: 24px !important;
        text-align: justify;
    }
    // .sous-title-secteur {
    //     font-size: 12px !important;
    //     text-align: justify;
    // }
    .desc-mytribu-mobile {
        font-size: 13px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media (min-width:300px) and (max-width: 700px) {
    .sous-title-secteur {
        font-size: 12px !important;
        text-align: justify;
    }
}

@media (min-width: 400px) and (max-width: 900px) {
    .sous-title-secteur {
        font-size: 14px !important;
        text-align: justify;
    }
}