:root {
  --color_1: #4f1d4a;
  --color_1_1: #662660;
  --color_2: #69c0ac;
  --color_2_1: #509585;
  --color_3: #ffffff;
  --color_4: #e2b00d;
  --color_5: #f1f0f1;
  --color_6: #05a9d2;
  --color_7: #cccccc;
  --color_8: #000000;
  --color_9: #e61972;
  --color_10: #e86a0e;
  --color_11: #341331;
  --color_12:#F1AF02;
  --box-shadow:.5rem .5rem 0 rgba(22, 160, 133, .2);
  --text-shadow:.4rem .4rem 0 rgba(0, 0, 0, .2);
  --border:.1rem solid var(--color_2);
  --borderIns:.1rem solid var(--color_1);

}
