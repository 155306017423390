.radio,
.radio-label,
.radio-label:after,
.radio-label:before {
    box-sizing: border-box;
}

.radio-container {
    .radio-inline {
        display: inline-block;
        margin-right: 10px;
    }
    .radio {
        display: inline;
        opacity: 0;
        width: 0;
        margin: 0;
        overflow: hidden;
        -webkit-appearance: none;
    }
    .radio-label {
        display: inline-block;
        height: 50px;
        position: relative;
        padding: 15px 10px 15px 28px;
        cursor: pointer;
        vertical-align: bottom;
        font-weight: 500;
        transition: color 200ms ease;
        &:before,
        &:after {
            position: absolute;
            content: "";
            border-radius: 50%;
            transition: transform 200ms ease, border-color 200ms ease;
        }
        &:before {
            left: 0;
            top: 15px;
            width: 20px;
            height: 20px;
            border: 2px solid rgb(219, 219, 219);
        }
        &:after {
            top: 20px;
            left: 5px;
            width: 10px;
            height: 10px;
            transform: scale(0);
            background-color: $col-secondary;
        }
        &:hover {
            color: $col-secondary;
            &:before {
                border-color: $col-secondary;
            }
        }
    }
    .radio:checked {
        &+.radio-label {
            color: $col-secondary;
        }
        &+.radio-label:before {
            border-color: $col-secondary;
        }
        &+.radio-label:after {
            transform: scale(1);
        }
    }
}

.form-control,
.form-select {
    padding: 10px 0.75rem !important;
    border-radius: 0 !important;
}

.input-group {
    .form-control {
        border-right: none !important;
    }
    .input-group-text {
        background-color: transparent !important;
    }
}

.radio-primary {
    .radio-label {
        &:after {
            background-color: $col-primary;
        }
        &:hover {
            color: $col-primary;
            &:before {
                border-color: $col-primary;
            }
        }
    }
    .radio:checked {
        &+.radio-label {
            color: $col-primary;
        }
        &+.radio-label:before {
            border-color: $col-primary;
        }
        &+.radio-label:after {
            transform: scale(1);
        }
    }
}

.form-select {
    color: #6e777f;
}

.c-check__checkbox {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.c-check__checkbox {
    cursor: pointer;
    padding-left: 2rem;
    vertical-align: middle;
    position: relative;
}

.c-check__checkbox::before,
.c-check__checkbox::after {
    content: "";
    display: block;
    position: absolute;
}

.c-check__checkbox::before {
    background-color: #fff;
    border-radius: 0%;
    border: 2px solid #666464;
    width: 32px;
    height: 32px;
    transform: translateY(-50%);
    top: 50%;
    left: 0.25rem;
    border-radius: 50%;
}

.c-check__checkbox::after {
    background: $col-secondary;
    opacity: 0;
    height: 20px;
    width: 20px;
    top: -10px;
    left: 10px;
    transition: opacity 0.3s;
    border-radius: 50%;
}

.c-check__checkbox:checked::before {
    border: 2px solid $col-secondary;
}

.c-check__checkbox:checked::after {
    opacity: 1;
}


/***/

.c-check__checkbox_ {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.c-check__checkbox_ {
    cursor: pointer;
    padding-left: 4rem;
    vertical-align: middle;
    position: relative;
}

.c-check__checkbox_::before,
.c-check__checkbox_::after {
    content: "";
    display: block;
    position: absolute;
}

.c-check__checkbox_::before {
    background-color: #fff;
    border-radius: 0%;
    border: 2px solid #666464;
    width: 32px;
    height: 32px;
    transform: translateY(-50%);
    top: 50%;
    left: 0.25rem;
    border-radius: 50%;
}

.c-check__checkbox_::after {
    background: $col-primary;
    opacity: 0;
    height: 20px;
    width: 20px;
    top: -10px;
    left: 10px;
    transition: opacity 0.3s;
    border-radius: 50%;
}

.c-check__checkbox_:checked::before {
    border: 2px solid $col-primary;
}

.c-check__checkbox_:checked::after {
    opacity: 1;
}

.c-check__label {
    cursor: pointer;
}