@import "./src/assets/abstracts/color.scss";
@import "./src/assets/components/_responsive.scss";
@import "./src/assets/abstracts/_variables.scss";
@import "./src/assets/components/_input.scss";
@import "./src/assets/components/_button.scss";
@import "./src/assets/components/_login.scss";
@import "./src/assets/components/_sliders.scss";
@import "./src/assets/components/_style.scss";
@import "./src/assets/components/_typography.scss";
@import "./src/assets/components/_card.scss";
@import "./src/assets/components/_dialog.scss";
@import "./src/assets/components/_menu.scss";
@import "./src/assets/components/_font.scss";
@import "./src/assets/components/_tabs.scss";
@import "./src/assets/components/_dropdown.scss";
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');