    .content-slider {
        .carousel-item {
            height: 600px !important;
        }
    }
    
    .content-avantages {
        .carousel-item {
            margin: 40px 10px;
        }
        .carousel-caption {
            top: 0;
            bottom: unset !important;
            left: unset !important;
            right: unset !important;
            color: #fff;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
        }
    }
    
    carousel {
        .carousel-control-next,
        .carousel-control-prev {
            opacity: 1 !important;
        }
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            width: 3rem !important;
            height: 3rem !important;
        }
        .carousel-control-prev-icon {
            background-image: url(../images/icons/icon-left.svg) !important;
        }
        .carousel-control-next-icon {
            background-image: url(../images/icons/icon-right.svg) !important;
        }
    }
    
    .slider-form {
        .text-slider-form {
            position: relative;
            top: -50px;
        }
        .carousel-control-next {
            right: 35%;
        }
        .carousel-control-prev {
            left: 35%;
        }
        .carousel-control-next,
        .carousel-control-prev {
            width: 50.16px;
            height: 50.16px;
            top: unset;
            bottom: -150px;
        }
    }
    
    .slider-primary {
        .carousel-control-prev-icon {
            background-image: url(../images/icons/icon-left-primary.svg) !important;
        }
        .carousel-control-next-icon {
            background-image: url(../images/icons/icon-right-primary.svg) !important;
        }
    }
    
    .slider-form-third {
        .text-slider-form {
            position: relative;
            top: -50px;
        }
        .carousel-control-next {
            right: -100px;
        }
        .carousel-control-prev {
            left: -105px;
        }
        .carousel-control-next,
        .carousel-control-prev {
            width: 50.16px;
            height: 50.16px;
            top: unset;
            bottom: 300px;
        }
    }
    
    .slider-third {
        .carousel-control-prev-icon {
            background-image: url(../images/icons/icon-left-secondary.svg) !important;
        }
        .carousel-control-next-icon {
            background-image: url(../images/icons/icon-right-secondary.svg) !important;
        }
    }
    
    .slider-secondary {
        .carousel-control-prev-icon {
            background-image: url(../images/icons/icon-left-secondary.svg) !important;
        }
        .carousel-control-next-icon {
            background-image: url(../images/icons/icon-right-secondary.svg) !important;
        }
    }
    
    .slider-home {
        .carousel-control-next {
            right: 24vw;
        }
        .carousel-control-prev {
            left: unset;
            right: 27vw;
        }
        .carousel-control-next,
        .carousel-control-prev {
            width: 50.16px;
            height: 50.16px;
            top: unset;
            bottom: 106px !important;
        }
    }
    
    @media screen and (max-width: 1800px) {
        .slider-home {
            .carousel-control-next {
                right: 11vw;
            }
            .carousel-control-prev {
                right: 16vw;
            }
        }
    }
    
    @media screen and (max-width: 1200px) {
        .slider-home {
            .carousel-control-prev {
                right: 19vw;
            }
            .carousel-control-next,
            .carousel-control-prev {
                bottom: 50px;
            }
        }
    }
    
    @media screen and (max-width: 1200px) {
        .slider-home {
            .carousel-control-prev {
                right: 19vw;
            }
            .carousel-control-next,
            .carousel-control-prev {
                bottom: 50px;
            }
        }
    }
    
    @media screen and (max-width: 900px) {
        .content-slider .carousel-item {
            height: unset !important;
        }
        .slider-home {
            .carousel-control-prev {
                right: 23vw;
            }
            .carousel-control-next,
            .carousel-control-prev {
                bottom: 50px;
            }
        }
    }
    
    @media screen and (max-width: 767px) {
        .content-slider .carousel-item {
            height: unset !important;
        }
        .slider-home {
            .carousel-control-prev {
                right: 23vw;
            }
            .carousel-control-next,
            .carousel-control-prev {
                bottom: -50px;
            }
        }
        .slideShowHome {
            .carousel-control-next,
            .carousel-control-prev {
                opacity: 1 !important;
            }
            .carousel-control-prev-icon {
                background-image: url(../images/icons/icon-left.svg) !important;
                /*margin-right: -544px;*/
            }
            .carousel-control-next-icon {
                background-image: url(../images/icons/icon-right.svg) !important;
                /* margin-left: -60px;*/
            }
            .carousel-control-next-icon,
            .carousel-control-prev-icon {
                width: 2rem !important;
                height: 2rem !important;
                float: right;
                margin-top: 250px;
            }
        }
        .content-avantages .carousel-item {
            margin: 40px 0px 0px 0px !important;
            padding: 0px !important;
        }
        .slideFooter {
            .carousel-control-next,
            .carousel-control-prev {
                opacity: 1 !important;
            }
            .carousel-control-prev-icon {
                background-image: url(../images/icons/icon-left.svg) !important;
                margin-right: -51px;
                width: 2rem !important;
                height: 2rem !important;
            }
            .carousel-control-next-icon {
                background-image: url(../images/icons/icon-right.svg) !important;
                margin-left: -51px;
                width: 2rem !important;
                height: 2rem !important;
            }
            .carousel-control-next-icon,
            .carousel-control-prev-icon {
                margin-bottom: 250px;
            }
        }
        .btnRejoignez1 {
            position: relative;
            // top: 260px !important;
            top: 287px !important;
        }
        .margeTop1 {
            margin-top: -120px;
        }
        .txtMytribu {
            font-size: 24px !important;
        }
        .btnResponsiveJuridique {
            position: relative;
            padding-top: 2rem !important;
            padding-left: 27px;
            padding-right: 0px;
            top: -378px;
            /*text-align: center;*/
        }
        .btnResponsiveRestau {
            position: relative;
            padding-top: 2rem !important;
            padding-left: 0px;
            padding-right: 43px;
            top: -368px;
        }
        .btnResponsiveMedical {
            position: relative;
            top: -301px;
            padding-top: 2rem !important;
            padding-left: 0px;
            padding-right: 27px;
        }
        .btnResponsiveBeaute {
            position: relative;
            top: -352px;
            padding-top: 2rem !important;
            padding-left: 27px;
            padding-right: 0px;
        }
        .btnResponsiveTpePme {
            position: relative;
            top: -399px;
            padding-top: 2rem !important;
            padding-left: 0px;
            padding-right: 27px;
        }
        .bgColor {
            background-color: var(--color_5) !important;
            border: 2px solid $col-primary;
            padding: 7px 25px;
            color: $col-primary;
            font-weight: 500;
        }
        .btnResponsiveCnx {
            background: $col-secondary;
            border: 2px solid $col-secondary;
            padding: 7px 25px;
        }
        .mtResponsive {
            margin-top: -60px !important;
        }
        .mbottomResponsive {
            margin-bottom: -40px !important;
        }
    }
    
    @media screen and (max-width: 599px) {
        carousel {
            .carousel-control-next,
            .carousel-control-prev {
                position: absolute !important;
                top: 300px !important;
            }
            .carousel-control-next {
                /*right: -25px !important;*/
            }
            .carousel-control-prev {
                /*left: -25px !important;*/
            }
        }
    }
    
    @media (min-width:300px) and (max-width: 700px) {
        .slider-form-third .carousel-control-next {
            right: 122px !important;
        }
        .slider-form-third .carousel-control-prev {
            left: 129px !important;
        }
        .slider-form-third .carousel-control-next,
        .slider-form-third .carousel-control-prev {
            bottom: 189px !important;
        }
        carousel {
            .carousel-control-next,
            .carousel-control-prev {
                top: 310px !important;
            }
        }
    }
    
    @media (min-width:400px) and (max-width: 900px) {
        .slider-form-third .carousel-control-next {
            right: 154px !important;
        }
        .slider-form-third .carousel-control-prev {
            left: 138px !important;
        }
        .slider-form-third .carousel-control-next,
        .slider-form-third .carousel-control-prev {
            bottom: 189px !important;
        }
        carousel {
            .carousel-control-next,
            .carousel-control-prev {
                top: 310px !important;
            }
        }
    }