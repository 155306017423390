.content-secteur {
    position: relative;
    &::before {
        content: "";
        background: $col-bg-gris;
        height: 76pc;
        width: 100%;
        position: absolute;
        left: 0;
        top: 190px;
        z-index: -1;
    }
}

.img-secteur {
    width: 82%;
    height: 35pc;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    transition: all 0.5s;
    &:hover {
        border-radius: 40px;
    }
}

.secteur-1 {
    background: url(../images/section-1.jpg);
}

.secteur-2 {
    background: url(../images/section-2.jpg);
}

.secteur-3 {
    background: url(../images/section-3.jpg);
}

.secteur-4 {
    background: url(../images/section-4.jpg);
}

.secteur-5 {
    background: url(../images/section-5.jpg);
}

.modal-backdrop {
    background-color: $col-w !important;
}

.img-bg-dialog {
    background-repeat: no-repeat;
    background-size: cover;
    height: 850px;
}

.img-bg-login {
    background-image: url(../images/modal-compte.webp);
}

.img-bg-welcome {
    background-image: url(../images/welcome.jpg);
}

.img-bg-password {
    background-image: url(../images/bg-password.jpg);
}

.img-bg-secteur-1 {
    background-image: url(../images/section-1.jpg);
}

.img-bg-secteur-2 {
    background-image: url(../images/section-2.jpg);
}

/*.modal.show .modal-dialog {
    display: flex;
    align-items: center;
    height: 100vh;
}*/

.modal.show .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center; /* Centre horizontalement le modal */
    max-height: 100vh; /* Limite la hauteur totale pour les écrans plus petits */
    //padding: 0 20px; /* Gère les marges latérales de manière uniforme */
    //overflow-y: auto; /* Active le défilement si le contenu dépasse */
}

.modal-sm {
    max-width: 478px !important; /* Fixe une largeur maximale pour les petites modals */
    width: 100%; /* Adapte la largeur au conteneur */
    padding: 20px; /* Applique un espacement intérieur uniforme */
    //border-radius: 8px; /* Ajoute un design plus moderne */
    //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Ajoute une ombre pour la visibilité */
}


.box-info {
    height: 340px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border-radius: 10px 10px 0 0;
    position: relative;
    .data-info {
        background: $col-primary;
        color: $col-w;
        position: absolute;
        bottom: 0;
    }
    .badge-info {
        position: absolute;
        width: 110px;
        height: 75px;
        top: 30px;
        right: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        color: $col-w;
    }
}

// .info-1 {
//     background: url(../images/info-1.jpg);
// }
.col-badge-1 {
    background: $col-badge-1;
}

.col-badge-2 {
    background: $col-badge-2;
}

.col-badge-3 {
    background: $col-badge-3;
}

.col-badge-4 {
    background: $col-badge-4;
}

.box-option {
    height: 203px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
}

.image-pub {
    width: 100%;
    height: 203px;
    object-fit: contain;
}

.box-side {
    /*background: $col-primary;*/
    border-radius: 5px;
    color: $col-w;
    overflow: hidden;
    font-family: "Josefin Sans", sans-serif;
    position: relative;
    font-weight: 200;
    .titre-box-side {
        font-weight: 500;
    }
    .s-titre-box {
        border: 0;
        border-bottom: 5px;
        border-style: dotted;
        border-color: $col-bg-gris;
        font-weight: 400;
    }
    &::before {
        content: "";
        position: absolute;
        width: 90%;
        border: 0;
        left: 5%;
        border-bottom: 35px;
        border-style: dotted;
        border-color: $col-bg-gris;
        bottom: -16px;
    }
}

.card-offre {
    position: relative;
    top: unset;
    background: $col-w;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    .titre-card-offre {
        min-height: 95px;
        background: $col-primary;
        color: $col-w;
        & div:nth-child(1) {
            font-family: "PoetsenOne", sans-serif;
        }
    }
}