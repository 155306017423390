body {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
}

.page-contact p {
    font-family: 'Poppins', sans-serif !important;
}

.top-position {
    position: relative;
    top: -90px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.content-inner-pages {
    min-height: auto !important;
    /*957px*/
    background: $col-bg-gris;
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
}

.page-contact {
    .box-contact div {
        &:nth-child(1) {
            height: 90px;
        }
        &:nth-child(2) {
            font-size: 18px;
            min-height: 70px;
        }
        &:nth-child(3) {
            font-size: 15px;
        }
    }
}

.page-mentions {
    background-image: url("../images/mentions-bg.jpg");
    .box-mentions-1 {
        padding-top: 50px;
    }
    .box-mentions-2 div {
        &:nth-child(1) {
            height: 50px;
        }
    }
}

.page-sommes {
    background-image: url("../images/sommes-bg.jpg");
}

.page-rejoindre {
    background-image: url("../images/rejoindre.png");
}

.mt-form {
    margin-top: 270px !important;
}

.content-home-site {
    background: $col-bg-gris;
}

.btn-rej-mobile {
    display: none;
}

.btn-rej-web {
    display: block;
}