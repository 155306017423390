$col-primary: #4f1d4a;
$col-secondary: #69bfab;
$col-secondary-hover: #559f8e;
$col-bg-gris: #f1f0f1;
$col-badge-1: #E61972;
$col-badge-2: #E2B00D;
$col-badge-3: #69C0AC;
$col-badge-4: #E86A0E;


$col-w:#ffffff;
$col-B:#000000;
$col-G: #C4C4C4;
