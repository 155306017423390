.drop-filter {
  background: $col-bg-gris;
  min-width: 14rem;

  .item-drop {
    background: $col-w;
    margin: 4px;
    padding: 5px;
    border-radius: 10px;
    color: $col-primary;
    cursor: pointer;
    text-align: center;

    &:hover {
      background: $col-primary;
      color: $col-w;
    }
  }
}

.drop-menu {
  background: $col-primary !important;
  color: $col-w !important;
  min-width: 14rem;
  padding: 10px;
  .dropdown-divider {
    border-top: 1px solid rgb(255 255 255 / 15%);
  }
}
