@font-face {
  font-family: JosefinSans_Regular;
  src: url("../font/JosefinSans-Regular.ttf");
}
@font-face {
  font-family: JosefinSans_SemiBold;
  src: url("../font/JosefinSans-SemiBold.ttf");
}
@font-face {
  font-family: JosefinSans_Bold;
  src: url(../font/JosefinSans-Bold.ttf);
}
@font-face {
  font-family: JosefinSans_Light;
  src: url(../font/JosefinSans-Light.ttf);
}
@font-face {
  font-family: Berlin_Sans_FB_Regular;
  src: url(../font/Berlin-Sans-FB-Regular.ttf);
}