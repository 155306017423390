@import url("color.css");
@import url("font.css");

/* Évite le "bounce" lors du scroll */
body {
    overscroll-behavior: none;
    width: 100%;
    max-width: 100%;
  }
  
  /* Empêche le zoom tactile */
  html, body {
    touch-action: manipulation;
    overflow-x: hidden; /* Supprime les marges ou débordements horizontaux */
    margin: 0; /* Assurez-vous qu'il n'y a pas de marges globales */
  }
/* Réinitialisation des marges pour tous les éléments */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  
  /* Supprime le "double-tap to zoom" sur les boutons et liens */
  button, a {
    -webkit-tap-highlight-color: transparent;
  }
  

.colorSelect {
    color: gray;
}

.bodyColorWhite{
    background-color: white;
}
.bodyColor{
    background-color: var(--color_5);
}
/* CssButtonRadioConnect */

.form-box {
    max-width: 400px;
}

.form-box label input {
    display: none;
}

.form-box label {
    display: inline;
    cursor: pointer;
    padding-left: 35px;
    position: relative;
}

.form-box label .circle {
    height: 20px;
    width: 20px;
    border: 1px solid var(--color_8);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0px;
    transition: var(--color_1) .5s ease;
}

.form-box label input:checked+.circle {
    border-color: var(--color_2);
}

.form-box label .circle::before {
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: var(--color_2);
    left: 0;
    top: 0;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: 4px;
    margin-top: 4px;
    transition: transform .5s ease;
    transform: scale(0);
}

.form-box label span {
    font-size: 14px;
    color: var(--color_8);
    display: inline;
    transition: var(--border) 5s ease;
}

.form-box label input:checked+.circle::before {
    transform: scale(1);
}

.form-box label input:checked+.circle+span {
    color: var(--color_2);
}


/* EndCssButtonRadioConnect */


/* CssButtonRadioInscription */

.form-boxIns {
    max-width: 400px;
}

.form-boxIns label input {
    display: none;
}

.form-boxIns label {
    display: inline;
    cursor: pointer;
    padding-left: 35px;
    position: relative;
}

.form-boxIns label .circle {
    height: 20px;
    width: 20px;
    border: 1px solid var(--color_8);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0px;
    transition: var(--color_1) .5s ease;
}

.form-boxIns label input:checked+.circle {
    border-color: var(--color_1);
}

.form-boxIns label .circle::before {
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: var(--color_1);
    left: 0;
    top: 0;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: 4px;
    margin-top: 4px;
    transition: transform .5s ease;
    transform: scale(0);
}

.form-boxIns label span {
    font-size: 14px;
    color: var(--color_8);
    display: inline;
    transition: var(--border) 5s ease;
}

.form-boxIns label input:checked+.circle::before {
    transform: scale(1);
}

.form-boxIns label input:checked+.circle+span {
    color: var(--color_1);
}


/* EndCssButtonRadioInscription */


/* cssDebutButtonConnectionPoppin */

input[type="radio"]:checked+label {
    color: #69c0ac !important;
}

input[type="radio"].buttonPoppin {
    display: none;
}

input[type="radio"].buttonPoppin+label {
    position: relative;
    padding-left: 1.3rem;
}

input[type="radio"].buttonPoppin+label::before,
input[type="radio"].buttonPoppin+label::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    border-radius: 1rem;
}

input[type="radio"].buttonPoppin+label::before {
    bottom: 0;
    left: 0;
    border: 1px solid #ccc;
    background-color: #eee;
    width: 1rem;
    height: 1rem;
}

input[type="radio"].buttonPoppin+label::after {
    bottom: 3px;
    left: 3px;
    width: calc(1rem - 6px);
    height: calc(1rem - 6px);
}

input[type="radio"].buttonPoppin:checked+label::after {
    background-color: #69c0ac;
}

.labelForm {
    line-height: 1.1;
    display: inline;
}


/* finCss buttonConnectionPoppin */


/* Css ButtonsConnection */

input[type="radio"]:checked+label {
    color: #69c0ac !important;
}

input[type="radio"].demo6 {
    display: none;
}

input[type="radio"].demo6+label {
    position: relative;
    padding-left: 1.3rem;
}

input[type="radio"].demo6+label::before,
input[type="radio"].demo6+label::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    border-radius: 1rem;
}

input[type="radio"].demo6+label::before {
    bottom: 0;
    left: 0;
    border: 1px solid #ccc;
    background-color: #eee;
    width: 1rem;
    height: 1rem;
}

input[type="radio"].demo6+label::after {
    bottom: 3px;
    left: 3px;
    width: calc(1rem - 6px);
    height: calc(1rem - 6px);
}

input[type="radio"].demo6:checked+label::after {
    background-color: #69c0ac;
}

.labelForm {
    line-height: 1.1;
    display: inline;
}


/* finCssRadioConnection */

a {
    text-decoration: none;
    /* display: inline-block;*/
    padding: 5px 12px;
}

.paddingT10 a {
    padding: 0px 0px !important;
}

a:hover {
    /*background-color: #ddd;*/
    color: black;
}

.previous {
    background-color: var(--color_5);
    color: var(--color_1);
}

.next {
    background-color: var(--color_5);
    color: var(--color_1);
    font-weight: bolder;
}

.round {
    border-radius: 20%;
    border: 3px solid var(--color_1);
    justify-content: space-between;
}


/* debutCssRadioInscription */

input[type="radio"]:checked+label {
    color: #4f1d4a !important;
}

input[type="radio"].radioIns {
    display: none;
}

input[type="radio"].radioIns+label {
    position: relative;
    padding-left: 1.3rem;
}

input[type="radio"].radioIns+label::before,
input[type="radio"].radioIns+label::after {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    border-radius: 1rem;
}

input[type="radio"].radioIns+label::before {
    bottom: 0;
    left: 0;
    border: 1px solid #ccc;
    background-color: #eee;
    width: 1rem;
    height: 1rem;
}

input[type="radio"].radioIns+label::after {
    bottom: 3px;
    left: 3px;
    width: calc(1rem - 6px);
    height: calc(1rem - 6px);
}

input[type="radio"].radioIns:checked+label::after {
    background-color: #4f1d4a;
}


/* finCssRadioinscription */

.bradius:hover {
    border-radius: 40px;
    margin-left: 0em;
    float: left;
}

.btn1 {
    display: inline-block;
    margin-top: 1rem;
    padding: .5rem;
    padding-left: 1rem;
    border: var(--border);
    color: var(--color_2);
    cursor: pointer;
    font-size: 17px;
    background: #fff;
    width: 100%;
    height: 40px;
    border-radius: 0rem;
}

.btn1:hover {
    background: var(--color_2);
    color: #fff;
}

.btnCpte {
    width: 180px;
    height: 58.82px;
    background-color: var(--color_2);
    color: var(--color_3);
    border: var(--color_2);
    font-family: JosefinSans_SemiBold;
    font-size: 16px;
    border-radius: 0rem;
}

.btnCpte:hover {
    background-color: var(--color_2_1);
    color: #fff;
}

.btnSeconnecter {
    width: 180px;
    height: 58.82px;
    background-color: var(--color_2);
    color: var(--color_3);
    border: var(--color_2);
    font-family: JosefinSans_SemiBold;
    font-size: 16px;
    border-radius: 0rem;
}

.btnSeconnecter:hover {
    background-color: var(--color_2_1);
    color: #fff;
}

.btnIns {
    padding: 15px 25px;
    border: 2px solid var(--color_1);
    color: var(--color_1);
    cursor: pointer;
    font-size: 17px;
    background: #fff;
    border-radius: 0rem;
}

.btnIns:hover {
    background: var(--color_1);
    color: #fff;
}

.testTop {
    margin-top: 130px;
    border-radius: 0rem;
}

.btnRejoigneznous {
    display: inline-block;
    margin-top: 1rem;
    padding: .5rem;
    padding-left: 1rem;
    border: 1px solid var(--color_1);
    color: var(--color_1);
    cursor: pointer;
    font-size: 17px;
    background: #fff;
    width: 180px;
    height: 58.82px;
    border-radius: 0rem;
}

.btnRejoigneznous:hover {
    background: var(--color_1);
    color: #fff;
}

.carre {
    position: relative;
    top: -60px;
    background-color: var(--color_1);
    width: 300px;
    height: 100px;
    overflow: visible;
}

.mtop {
    margin-top: 120px;
}


/* home */

.formbg {
    background-color: var(--color_3);
    width: 45%;
}

.formbgConnect {
    width: 50%;
}

.M_btn_CAv {
    /* width: 100%;
  height: 40px; */
    padding: 17px 25px;
    background-color: var(--color_2);
    color: var(--color_3);
    border: var(--color_2);
    font-family: JosefinSans_SemiBold;
    font-size: 16px;
    border-radius: 0rem;
}

.M_btn_CAv:hover {
    background-color: var(--color_2_1);
}
.M_btn_CAv_Client{
    /* width: 100%;
  height: 40px; */
    padding: 17px 25px;
    background-color: var(--color_1);
    color: var(--color_3);
    border: var(--color_1);
    font-family: 'Poppins';
    font-size: 16px;
    border-radius: 0rem;
}

.M_btn_CAv_Client:hover {
    background-color: var(--color_1_1);
}
button:focus {
    outline: none;
}

.M_plf {
    padding-left: 30px;
}

.M_Txt14 {
    font-size: 14px;
    font-family: Poppins;
}
.M_Txt {
    font-size: 16px;
    font-family: Poppins;
}
.M_Txt19 {
    font-size: 19px;
    font-family: Poppins;
}
.M_Top {
    /*margin-top: 20px;*/
    background-color: var(--color_1);
    height: 95px;
}
.M_Top_P{
    /*margin-top: 20px;*/
    background-color: var(--color_12);
    height: 95px;
}
.M_LogoImg {
    width: 270px;
    margin: 10px;
}

.M_TextSB {
    font-family: Poppins;
}

.M_TextPromo {
    color: var(--color_2);
}

.M_TextR {
    font-family: JosefinSans_Regular;
}

.M_pb15 {
    padding-bottom: 15px;
}

.M_grid {
    display: grid;
}

.M_TBold {
    font-weight: 700
}
.radio-container .radio-label{
    font-size: 16px !important;
    font-family: Poppins !important;
}
.pr-0{
    padding-right: 0px;
}
.pl-0{
    padding-left: 0px;
}
.M_bg_col1 {
    background-color: var(--color_1) !important;
}

.M_bg_col2 {
    background-color: var(--color_2) !important;
}

.M_bg_col4 {
    background-color: var(--color_4) !important;
}

.M_TextC1 {
    color: var(--color_8) !important;
   font-weight: bold !important;;
}

.M_TextC2 {
    color: var(--color_2) !important;
}

.M_TextC2L {
    color: var(--color_2) !important;
    text-decoration: underline;
}

.M_TextC3 {
    color: var(--color_6) !important;
}
.btn-icon {
    pointer-events: auto !important;
    z-index: 999 !important;
  }
.M_Txt16{
    font-size: 16px !important;
}
.M_Txt14{
    font-size: 14px !important;
}
.M_pb60 {
    padding-bottom: 60px;
}

.M_header {
    margin-bottom: 5px;
    height: 84px;
    background-color: var(--color_3)
}

.M_ContentOffre {
    background: url(../img/bgOffre.jpg);
    background-size: inherit;
    background-repeat: no-repeat;
    height: 400px;
    background-position: center;
}


/*.M_ContentCompte {
    background: url(../img/bg_compte.png);
    background-size: inherit;
    background-repeat: no-repeat;
    height: 400px;
    background-position: center;
}*/

.M_BtnConnect {
    width: 120px;
    height: 40px;
    margin-left: 115px;
    font-family: JosefinSans_SemiBold;
    background-color: var(--color_1);
    color: var(--color_3);
    border: none;
    border-radius: 5px;
}

.M_btn_SC {
    border: none;
    background: transparent;
    cursor: pointer;
}

.M_TabsLogin {
    color: var(--color_3) !important;
    border-color: transparent !important;
    font-family: JosefinSans_SemiBold;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-size: 16px;
    padding: 4px 1rem !important;
}

.M_TabsMenu {
    color: var(--color_3) !important;
    border-color: transparent !important;
    font-family: JosefinSans_SemiBold;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-size: 13px;
    padding: 4px 1rem !important;
}

.M_ContentLogin .tab-content {
    background-color: var(--color_3);
}

.M_ConTopLogin {
    /*position: absolute;
    right: 0;*/
    top: 22px;
}

.M_logoInsc {
    margin-left: auto;
}

.M_InputLogin {
    border: 1px solid var(--color_1) !important;
    font-family: JosefinSans_Regular;
    height: 40px !important;
    border-radius: 0rem;
}

.M_InputLoginClient {
    border: 1px solid #e9ecef !important;
    font-family: 'Poppins';
    font-size: 12px !important;
    font-weight: 400 !important;
    height: 48px !important;
    border-radius: 0rem;
}
.labelFile {
    background-color: none;
    color: var(--color_1);
    padding: 0.5rem;
    font-family: sans-serif;
    cursor: pointer;
    text-decoration: underline;
}

.M_InputText {
    border: 1px solid var(--color_1) !important;
    font-family: JosefinSans_Regular;
    width: 100%;
    height: 100px !important;
    border-radius: 0rem;
    padding: 12px 20px;
}

.M_selectLogin {
    -webkit-appearance: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    /*background: url(../img/Shape.png)93% 50%;*/
    background-repeat: no-repeat;
    border-radius: 0rem;
    border: 1px solid var(--color_1) !important;
}

.M_SliderLogin {
    position: relative;
    margin: 0px auto;
    left: 0px;
    width: 545px;
    height: 390px;
    /* overflow: hidden; */
    visibility: visible;
}

.STop160 {
    top: 160px;
    /* position: relative*/
}

.STop60 {
    top: 65px;
    position: relative
}

input#CodePromo {
    /*background: url(../img/icon_code.png)10px;*/
    background-repeat: no-repeat;
    text-indent: 20px;
}

.M_CodePromo {
    margin-top: 20px
}

.M_ContentSlider {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: var(--color_1);
}

.M_textSlider {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 545px;
    height: 50px;
    color: #FFF;
    overflow: hidden;
    cursor: default;
    background-color: var(--color_1);
    font-family: JosefinSans_SemiBold;
}

.M_ContentImgSlider {
    cursor: default;
    position: relative;
    top: 0px;
    left: 0px;
    width: 545px;
    height: 380px;
    overflow: hidden;
    border-top: 0px solid var(--color_1);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.M_CicleSlider {
    top: auto;
}


/*jssor slider loading skin spin css*/

.jssorl-009-spin img {
    animation-name: jssorl-009-spin;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes jssorl-009-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/*jssor slider bullet skin 032 css*/

.jssorb032 {
    position: absolute;
}

.jssorb032 .i {
    position: absolute;
    cursor: pointer;
}

.jssorb032 .i .b {
    fill: var(--color_2);
    fill-opacity: 0.7;
    stroke: var(--color_1);
    stroke-width: 1200;
    stroke-miterlimit: 10;
    stroke-opacity: 0.25;
}

.jssorb032 .i:hover .b {
    fill: #000;
    fill-opacity: .6;
    stroke: #fff;
    stroke-opacity: .35;
}

.jssorb032 .iav .b {
    fill: var(--color_1);
    fill-opacity: 1;
    stroke: #fff;
    stroke-opacity: .35;
}

.jssorb032 .i.idn {
    opacity: .3;
}

.M_Footer {
    background-color: var(--color_1);
}

.M_LogoTextB {
    font-family: JosefinSans_SemiBold;
    font-size: 30px;
    color: var(--color_3);
    line-height: 0.602;
    text-align: left;
}

.M_FollowTextB {
    font-family: JosefinSans_SemiBold;
    font-size: 24px;
    color: var(--color_3);
    line-height: 0.602;
    text-align: left;
    padding-left: 40px;
}

.M_F_Box {
    /* border-right: 2px solid var(--color_3); */
    height: 27px;
    padding: -1px;
    display: grid;
}

.M_TextF {
    font-size: 12px;
    padding-left: 1px;
    list-style: none;
}

.M_btn_C1 {
    width: 100%;
    height: 40px;
    background-color: var(--color_1);
    color: var(--color_3);
    border: none;
    border-radius: 0rem;
    font-family: JosefinSans_SemiBold;
    font-size: 17px;
}

.M_btn_C2 {
    width: 100%;
    height: 40px;
    background-color: var(--color_3);
    color: var(--color_2);
    border: 2px solid var(--color_2);
    border-radius: 0rem;
    font-family: JosefinSans_SemiBold;
    font-size: 17px;
}

.M_textBoxLogin {
    font-family: JosefinSans_Bold;
    color: var(--color_1);
    font-size: 14px;
}

.M_BoxLogin {
    padding: 10px 0px 25px 0px;
    position: relative;
    top: 40px
}

.M_TabsInsc {
    border-bottom: 15px solid var(--color_1);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top: 5px solid var(--color_1) !important;
}

.M_TabsCon {
    border-bottom: 15px solid var(--color_2);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top: 5px solid var(--color_2) !important;
    padding-bottom: 10px;
}

.M_btn_Login {
    width: 100%;
    height: 40px;
    background-color: var(--color_2);
    color: var(--color_3);
    border: none;
    border-radius: 10px;
    font-family: JosefinSans_SemiBold;
    font-size: 17px;
}

.M_btn_Renouv {
    width: 100%;
    height: 40px;
    background-color: var(--color_2);
    color: var(--color_3);
    border: none;
    border-radius: 10px;
    font-family:  Poppins ;
    font-size: 14px;
    &:hover {
        background-color: #69C0AC;
        color: white;
    }
}
.M_btn_accueil_etab {
    width: 35%;
    height: 50px;
    /* background-color: #69c0ac; */
    background-color: #ffffff;
    color: #4F1D4A;
    border: 2px solid #4F1D4A;
    /* border-radius: 10px; */
   font-family:  Poppins ;
    font-size: 14px;
    &:hover {
        background-color: #4F1D4A;
        color: white;
    }}
.M_btn_accueil {
    width: 35%;
    height: 50px;
    /* background-color: #69c0ac; */
    background-color: #ffffff;
    color: var(--color_2);
    border: 2px solid #69c0ac;
    /* border-radius: 10px; */
   font-family:  Poppins ;
    font-size: 14px;
    &:hover {
        background-color: #69C0AC;
        color: white;
    }
}


.M_btn_LoginCode {
    width: 100%;
    height: 48px;
    /*background-color: #495057;
    color: black;*/
    font-weight: 300;
  /* border-radius: 10px;*/
    font-family: Poppins;
    /* font-size: 17px; */
}


/* The M_radioNewLett start */


/* The M_radio */

.M_radioNewLett {
    font-family: JosefinSans_Regular;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}


/* Hide the browser's default radio button */

.M_radioNewLett input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmarkNewLetter {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #cecece;
    border: 1px solid var(--color_1);
    border-radius: 50%;
}


/* When the radio button is checked, add a blue background */

.M_radioNewLett input:checked~.checkmarkNewLetter {
    border: 1px solid var(--color_1);
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmarkNewLetter:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.M_radioNewLett input:checked~.checkmarkNewLetter:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.M_radioNewLett .checkmarkNewLetter:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color_1);
}


/* The M_radioNewLett end */


/* The M_radio */

.M_radio {
    font-family: JosefinSans_Regular;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}


/* Hide the browser's default radio button */

.M_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.M_Text_Mail {
    text-decoration: underline;
    color: var(--color_1);
}


/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #cecece;
    border: 1px solid var(--color_1);
    border-radius: 50%;
}


/* When the radio button is checked, add a blue background */

.M_radio input:checked~.checkmark {
    /* background-color: var(--color_1); */
    border: 1px solid var(--color_2_1);
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.M_radio input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.M_radio .checkmark:after {
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color_2_1);
}

.M_InputPsw {
    margin-top: 10px
}

.M_MdpText {
    color: #000;
    text-decoration: underline;
    font-family: JosefinSans_Regular;
    cursor: pointer;
}

.titreDecoration {
    text-decoration: underline;
}

.M_SavePsw {
    color: var(--color_2);
}

.M_TextItemC {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-weight: normal;
    line-height: 50px;
    font-size: 14px;
    padding-left: 10px;
    box-sizing: border-box;
}

.M_BoxColor {
    display: grid;
    width: 100px;
    height: 75px;
    position: absolute;
    right: 30px;
    top: 30px;
    text-align: center;
    color: var(--color_3);
}

.M_BoxColor i {
    font-size: 30px;
    padding-top: 10px;
    opacity: 0.8
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    /* border-color: #80bdff; */
    outline: 0;
    box-shadow: none;
}

.M_Offre {
    background-color: var(--color_3);
    /*border-radius: 30px;*/
    position: relative;
    top: -126px;
    padding-bottom: 20px;
    height: max-content;
    padding: 0px 0px;
}

.M_TitreOffre {
    border-bottom: 7px dotted var(--color_1);
    padding: 23px 3px 0 0px;
}

.M_LogoOffre {
    position: absolute;
    top: 26px;
    right: 10px;
    width: 140px;
}

.M_TOffre {
    font-family: JosefinSans_SemiBold;
    color: var(--color_1);
    font-size: 25px;
}

.M_TOffre2 {
    font-family: JosefinSans_SemiBold;
    color: var(--color_2);
    font-size: 15px;
    text-transform: uppercase;
}

.M_txtOffreBox {
    background-color: var(--color_1);
    color: var(--color_3);
    text-align: center;
    font-family: JosefinSans_SemiBold;
    font-size: 14px;
    padding: 10px;
    border-bottom: 5px dotted var(--color_3);
}

.M_txtOffreBox2 {
    background-color: var(--color_1);
    color: var(--color_3);
    text-align: center;
    font-family: JosefinSans_Light;
    font-size: 12px;
    padding: 10px;
}

.M_BPoint {
    border-bottom: 29px dotted var(--color_5);
    border-top: none;
    position: relative;
    top: -30px;
    width: 90%;
}

.M_hautBox {
    width: 100%;
    height: 58.82px;
    padding-top:15px;
    background-color: var(--color_1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--color_3);
    font-family: JosefinSans_SemiBold;
}
.M_hautBoxPs {
    width: 100%;
    height: 58.82px;
    background-color: var(--color_4);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--color_3);
    font-family:Poppins ;
    font-weight: normal;
    font-size: 14px;
    padding-top: 15px;
}
.M_hautBoxEs  {
    width: 100%;
    height: 58.82px;
    background-color: var(--color_11);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--color_3);
    font-family:Poppins ;
    font-weight: normal;
    font-size: 14px;
    padding-top: 15px;
}

.M_hautBoxEstablissement {
    width: 250px;
    height: 40px;
    background-color: var(--color_11);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--color_3);
    font-family: JosefinSans_SemiBold;
}

.M_hautBox img {
    padding: 6px;
}

.M_hautBoxEs  img {
   /* padding: 6px;*/
    height: 24px ;
    height: 24px;
    color: var(--color_3) !important;
}

.M_hautBoxEstablissement img {
    padding: 6px;
    height: 40px !important;
}

.M_BoxIcon {
    width: 55px;
    height: 40px;
    /* background-color: var(--color_4); */
    position: absolute;
    right: 15px;
    padding: 5px;
    line-height: 0.5;
}

.M_BoxIcon span {
    color: var(--color_3);
    font-family: JosefinSans_SemiBold;
    font-size: 9px;
}

.M_OfContainer {
    position: relative;
    top: 40px;
}
.M_OfContainerN {
    position: relative;
    top: 120px;
}
.M_TCOffre {
    font-size: 15px;
    text-align: justify;
    color: var(--color_8);
}

.M_BoxPromo {
    border: 1.5px dashed #000;
}

.M_btnPromo {
    border: none;
    height: 30px;
    width: 100px;
    background-color: var(--color_6);
    color: var(--color_3);
    text-align: center;
    font-size: 11px;
}

.M_InputPromo {
    height: 30px;
    border-radius: 0px;
    border: 1px solid var(--color_7);
}

.M_TPromo {
    font-size: 12px;
}

.M_LinkPromo i {
    font-size: 16px;
    padding: 7px 7px 0px 15px;
}

.M_LinkPromo {
    font-size: 9px;
}

.M_ImgB_Pro {
    transform: rotate(-10deg);
    position: relative;
    top: 2px;
}

.M_ImgB_2 {
    transform: rotate(10deg);
    position: relative;
    top: 2px;
}

.M_ImgHome {
    width: 100%;
    /*height: 300px;*/
    height: 342px;
    background-size: cover ;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-position: center !important;
}

.M_Text_B_H {
    font-family: JosefinSans_SemiBold;
    font-size: 14px;
}

.M_cardColor {
    width: 105px;
    height: 75px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-position: center !important;
    background-size: cover !important;
}

.M_cardColorOffre {
    width: 33px;
    height: 35px;
    position: absolute;
    /* right: 13px; */
    top: 11px;
    left: 22px;
    background-position: center !important;
    background-size: cover !important;
}
#mobileMenu{
    display: none;
}
#imgOffre{
    display: block;
}
.M_cardColorVignette {
    width: 55px;
    height: 40px;
    position: absolute;
    right: 15px;
    top: 10px;
    background-position: center !important;
    background-size: cover !important;
}

.M_cardColorVignetteSlider {
    width: 105px;
    height: 75px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-position: center !important;
    background-size: cover !important;
}

.M_cardColorText {
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 65px;
    background-position: center !important;
    background-size: cover !important;
}

.M_cardColor1 {
    width: 105px;
    height: 75px;
    background: var(--color_9);
    position: absolute;
    right: 30px;
    top: 30px;
}

.M_cardColor2 {
    width: 105px;
    height: 75px;
    background: var(--color_4);
    position: absolute;
    right: 30px;
    top: 30px;
}

.M_cardColor3 {
    width: 105px;
    height: 75px;
    background: var(--color_2);
    position: absolute;
    right: 30px;
    top: 30px;
}

.M_cardColor4 {
    width: 105px;
    height: 75px;
    background: var(--color_10);
    position: absolute;
    right: 30px;
    top: 30px;
}

.M_TopCardH {
    position: relative;
    top: 40px;
}

.M_IconCard {
    position: relative;
    top: 10px;
    display: grid !important;
    margin: auto;
    width: 30px
}

.M_TxtC {
    font-family: JosefinSans_SemiBold;
    position: relative;
    top: 10px;
}

.M_BoxR {
    width: 100%;
    height: 175px;
    background: var(--color_1);
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
}

.M_BoxR p {
    padding-top: 30px;
}

.M_BoxL {
    width: 100%;
    height: 175px;
    background: var(--color_2);
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
}

.M_BoxL p {
    padding-top: 30px;
}

.M_Flech {
    margin: auto;
    display: block;
    position: relative;
    top: 30px;
}

.M_promo {
    width: 100%;
    height: 175px;
    background-size: cover !important;
    background-position: center !important;
}

.M_IconBot {
    transform: rotate(-10deg);
    position: relative;
    top: 2px;
    margin-left: auto;
}

.M_TabsIndex {
    margin: 0px 5px 0px 5px;
}

.nav-tabs .nav-item.show .M_TabsMenu,
.nav-tabs .M_TabsMenu.active {
    background: var(--color_1) !important;
}

.M_CFiltre {
    height: 35px;
    padding: 0px 5px 0px 5px;
}

.M_btnF {
    background: var(--color_3);
    border: none;
    width: 100%;
    font-family: JosefinSans_SemiBold;
    color: var(--color_1);
    line-height: 2;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.M_btnF:focus {
    outline: none;
}

.M_btnF img {
    margin-left: 10px;
}

.M_btnF span {
    width: 100%
}

.M_DropMenu {
    top: 68px !important;
    width: 100%;
    background: var(--color_3);
    border-radius: 0px !important;
    padding: 0.2rem 0 !important;
}

.M_DropMenuCateg {
    width: 100%;
    background: var(--color_5);
    border-radius: 0px !important;
    /* margin-top: 19px; */
    padding: 0px !important;
}

.M_DropM {
    top: 11px !important;
    width: 100%;
    background: var(--color_5);
    border-radius: 0px !important;
}

.M_ItemMenu {
    background: var(--color_3);
    width: 95%;
    margin: auto;
    border-radius: 10px;
    text-align: center;
    padding: 0px !important;
    color: var(--color_1);
    margin-top: 5px;
}

.M_ItemM {
    background: var(--color_5);
    width: 98%;
    margin: auto;
    border-radius: 10px;
    text-align: center;
    padding: 0px !important;
    color: var(--color_1);
    margin-top: 2px;
    height: 35px;
    font-weight: 500;
    line-height: 2;
    cursor: pointer;
}

.M_ItemM:hover {
    background: var(--color_1);
    color: var(--color_3) !important;
}

.dropdown-item:first-child {
    border-top-left-radius: calc(.25rem - -5px) !important;
    border-top-right-radius: calc(.25rem - -5px) !important;
}

.dropdown-item:last-child {
    border-bottom-right-radius: calc(.25rem - -5px) !important;
    border-bottom-left-radius: calc(.25rem - -5px) !important;
}

.M_ItemMenu:hover {
    background: var(--color_1);
    color: var(--color_3) !important;
}

.M_SliderHome {
    /*width: 100%;*/
    height: 385px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 80% !important;
}

.M_LogoHome {
    margin: auto;
    display: block;
   /* margin-top: 10px;*/
    cursor: pointer;
    height: 64px;
    width: 172px;
}

.M_IconMenu {
    position: absolute;
    margin-top: 10px;
    cursor: pointer;
}

.M_rest {
    margin-top: 10px;
}

.M_rest0 {
    margin-top: 0px;
}

.M_restM {
    margin-top: 10px;
}

.M_restM0 {
    margin-top: 0px;
}

.M_btn_avatar {
    border: none;
    background: transparent;
    cursor: pointer;
    /*padding-right: 70px;*/
}

.M_btnMenuC {
    width: 100%;
    height: 58.82px;
    border: none;
    font-family: Poppins;
    background: var(--color_2);
    color: var(--color_3);
    margin-top: 5px;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    &:hover {
        background-color: #509585;
        /*color: white;*/
    }
}
.M_btnMenuCP {
    width: 100%;
    height: 58.82px;
    border: none;
    font-family: Poppins;
    background: var(--color_4);
    color: var(--color_3);
    margin-top: 5px;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    &:hover {
        background-color: var(--color_12);
        /*color: white;*/
    }
}
.M_CActive {
    background: var(--color_1);
}
.M_CActiveP {
    background: var(--color_12);
    &:hover {
        background-color: #e2b00d !important;
        /*color: white;*/
    }
}
[data-toggle="collapse"] .fa:before {
    content: "\f139";
    font-size: 20px;
}

[data-toggle="collapse"].collapsed .fa:before {
    content: "\f13a";
    font-size: 20px;
}

.M_iconColor {
    color: var(--color_1);
    position: absolute;
    right: 0;
    z-index: 9;
    /*top: 4pc;*/
}

.M_iconColorClient {
    color: var(--color_1);
    position: absolute;
    right: 58px;
    z-index: 9;
    /*top: 4pc;*/
}

.M_Card {
    border: none !important;
}

.M_Card .card-header {
    background-color: transparent !important;
}

.M_Card .btn {
    text-align: left !important;
    font-family: JosefinSans_SemiBold;
    padding: 0px !important;
}

.M_Card .btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.M_Card span {
    font-size: 17px;
    line-height: 1;
}

.M_TTcompte {
    color: var(--color_1);
    font-family: Poppins;
    font-size: 14px;
  /*  padding-left: 10px;*/
}
.M_TTcompteClient {
    color: var(--color_3);
    font-family: 'PoetsenOne';
    font-size: 40px;
    padding-left: 10px;
}
.M_TTcompteClientP {
    color: var(--color_12);
    font-family: 'PoetsenOne';
    font-size: 40px;
    padding-left: 10px;
}
.d-lg-block {
    display: block;
  }
  .d-lg-none {
    display: none;
  }


.M_btnCompte {
    float: right;
    border: none;
    padding: 10px 20px 10px 20px;
    font-family: JosefinSans_SemiBold;
    background-color: var(--color_2);
    border-radius: 10px;
    font-size: 20px;
}
.M_btnCompteClient {
    float: right;
    border: none;
    font-family: 'PoetsenOne';
    font-size: 24px;
}
.M_btnCompteEtablissement {
    float: right;
    border: none;
    padding: 10px 10px 10px 10px;
    font-family: JosefinSans_SemiBold;
    background-color: var(--color_3);
    border-radius: 10px;
    font-size: 24px;
}

.M_btnCompteEta {
    margin-left: 60px;
    margin-top: -20px;
    border: none;
    padding: 5px 10px 5px 10px;
    font-family: JosefinSans_SemiBold;
    background-color: var(--color_2);
    border-radius: 10px;
    font-size: 20px;
}

.M_TopIcon {
    margin-top: 50px;
}

.M_TabsC .nav-tabs .nav-item.show .nav-link,
.M_TabsC .nav-tabs .nav-link.active {
    background: var(--color_1) !important;
}

.M_TabsC .nav-link {
    border: none !important;
    color: var(--color_3) !important;
    background-color: var(--color_2) !important;
    font-family: JosefinSans_SemiBold;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-size: 13px;
    padding: 6px 1rem !important;
    margin-bottom: 10px !important;
}

.nav-link {
    color: var(--color_3) !important;
    font-size: 13px;
    margin: 0px 40px 0px 40px;
    cursor: pointer;
}

.M_TabsC .nav-link {
    margin: 0px 5px 0px 5px;
}

.M_TabsConnex li:first-child a {
    background: var(--color_1) !important;
    color: var(--color_3) !important;
    border-color: transparent !important;
    font-family: JosefinSans_SemiBold;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-size: 16px;
    padding: 4px 1rem !important;
}

.M_TabsConnex li:last-child a {
    background: var(--color_2) !important;
    color: var(--color_3) !important;
    border-color: transparent !important;
    font-family: JosefinSans_SemiBold;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-size: 16px;
    padding: 4px 1rem !important;
}

.M_itemCompte {
    list-style-type: none;
    text-align: center;
    color: var(--color_1);
    font-family: JosefinSans_Bold;
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
}

.M_TabsC>ul {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .M_TabsC>ul {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .M_TabsC>ul {
        max-width: 720px;
    }
    .col-md-3 {
        -webkit-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width: 992px) {
    .M_TabsC>ul {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .M_TabsC>ul {
        max-width: 1140px;
    }
}

.M_btnMenu {
    margin-top: -5px
}

[data-toggle="collapse"] .M_I:before {
    content: "\f107";
}

[data-toggle="collapse"].collapsed .M_I:before {
    content: "\f106";
}

.M_LAbonn {
    /* background: var(--color_1); */
    /* color: var(--color_3); */
    text-align: center;
    border-radius: 20px;
    padding-top: 15px;
    font-family: JosefinSans_SemiBold;
}

.M_btnMenuC:focus {
    background: var(--color_1) !important;
}

.M_Item_owl {
    width: 140px;
    height: 80px;
    border-radius: 20px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    box-shadow: 2px 6px 12px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    margin: auto;
}

.M_Item_owl_lg {
    width: 100%;
    height: 220px;
    border-radius: 20px;
    background-size: 80% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    box-shadow: 2px 6px 12px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.M_Item_owl_1 {
    width: 165px;
    height: 95px;
    border-radius: 20px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    box-shadow: 2px 6px 12px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    margin: auto;
}

.M_Item_owl img {
    position: absolute;
}

.M_Item_owl_lg img {
    position: absolute;
}

.M_Item_owl_1 img {
    position: absolute;
}

.M_carouselOwl .owl-stage-outer {
    margin: auto;
    display: block;
    width: 455px;
}

.M_carouselOwl .owl-next {
    float: right;
}

.M_carouselOwl .owl-prev {
    float: left;
}

.M_carouselOwl .owl-next:hover {
    background: transparent !important
}

.M_carouselOwl .owl-prev:hover {
    background: transparent !important
}

.M_carouselOwl .owl-next::before {
    content: url(assets/img/next.png);
}

.M_carouselOwl .owl-prev::before {
    content: url(assets/img/prev.png);
}

.M_carouselOwl .owl-nav {
    position: relative;
    top: -85px;
}

.M_carouselOwl .active {
    width: 150px !important;
    height: 100px;
}

.M_carouselOwl .owl-theme {
    margin: auto
}

.checkB {
    width: 165px;
    height: 95px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkB_1 {
    width: 140px;
    height: 80px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkB_lg {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkB[type="checkbox"]:checked~.IconCheck {
    background: url(assets/img/verified.svg);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.checkB_1[type="checkbox"]:checked~.IconCheck_1 {
    background: url(assets/img/preferenceCheck.svg);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.checkB_lg[type="checkbox"]:checked~.IconCheck_lg {
    background: url(assets/img/verified.svg);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.IconCheck {
    width: 30px;
    height: 30px;
    transition: 0.2s;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 7px;
}

.IconCheck_1 {
    width: 30px;
    height: 30px;
    transition: 0.2s;
    border-radius: 100%;
    position: absolute;
    right: 30px;
    bottom: 25px;
}

.IconCheck_lg {
    width: 50px;
    height: 50px;
    transition: 0.2s;
    border-radius: 100%;
    position: absolute;
    right: 30px;
    bottom: 10px;
}

.M_Ccheck {
    width: 165px;
    height: 95px;
    position: absolute;
    bottom: 0;
}

.M_Ccheck_lg {
    width: 165px;
    height: 95px;
    position: absolute;
    bottom: 0;
}

.M_Ccheck_lg1 {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.M_chText {
    width: 165px;
    height: 95px;
    background: #00000085;
    border-radius: 20px;
    padding-top: 20%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    opacity: 0;
    font-family: JosefinSans_Bold;
    text-transform: uppercase;
}

.M_chText_1 {
    width: 140px;
    height: 80px;
    background: #00000085;
    border-radius: 20px;
    padding-top: 20%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    opacity: 0;
    font-family: JosefinSans_Bold;
    text-transform: uppercase;
}

.M_Item_owl:hover .M_chText_1 {
    opacity: 1;
}

.M_Item_owl_lg:hover .M_chText {
    opacity: 1;
}

.M_Item_owl_1:hover .M_chText {
    opacity: 1;
}

.M_MTop {
    position: relative;
    top: -40px;
}

.mTop {
    margin-top: 9rem;
}

.M_SliderLogin .i {
    background: var(--color_2);
    border-radius: 100%;
    border: 1px solid var(--color_1);
}

.M_SliderLogin .iav {
    background: var(--color_1) !important;
}

.M_SliderLogin .i {
    width: 12px !important;
    height: 12px !important;
}

.scroll-to-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    opacity: 0;
    display: none;
    transition: all .2s ease-in-out;
}

.show-scrollTop {
    opacity: 1;
    display: block;
    transition: all .2s ease-in-out;
}

.M_Down {
    font-size: 40px;
    width: 60px;
    background-color: #4f1d4a;
    border: none;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    padding-top: 0px;
    border-bottom-right-radius: 5px !important;
    position: fixed;
    bottom: 5pc;
    /* right: 18pc; */
    right: 1pc;
}

.M_Go {
    outline: none;
    cursor: pointer;
}

.bannerPics {
    background-size: cover !important;
    background-position: center !important
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #4f1d4a;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}


/* .modal-content {
  border-radius: 15px !important;
} */

/*.modal-content {
    border-radius: 0px !important;
    border: 0px !important;
    margin-top: 80px;
    width: 400rem !important;
}*/

.long-name {
    font-size: 22px!important;
  
  }
  
  .short-name {
    font-size: 40px;
  
  }
.modal-content {
    border-radius: 0px !important; /* Ajoute un arrondi pour une apparence moderne */
    border: 0px !important; /* Supprime les bordures */
    margin-top: 80px; /* Garde le modal décalé du haut de l'écran */
    max-width: 90%; /* Assure que le modal ne dépasse pas la largeur de l'écran */
    width: 400rem; /* Fixe une largeur adaptée pour les écrans standards */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Ajoute une ombre pour une meilleure visibilité */
}

.field-icon {
    float: right;
    margin-top: -28px;
    position: relative;
    z-index: 2;
    width: 2.7em;
    color: #4f1d4a;
    font-size: larger;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #212529;
    /* text-align: center; */
    /* white-space: nowrap; */
    background-color: #e9ecef;
    border: 1px solid #212529;
    /* border-radius: 0.375rem; */
    font-family: JosefinSans_Regular;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    background-color: #e9ecef;
}

ng-select>div.disabled {
    background-color: transparent !important;
    color: rgb(17, 17, 17) !important;
    cursor: default;
    pointer-events: none;
}
ng-select>div>div.single>div.placeholder, ng-select>div>div.single>div.value{
    flex: 0.25 !important
}

.container {
    padding-top: 15px;
}

.input-group1 {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 50%;
}

.p-30 {
    padding: 30px 30px 30px 30px
}

.btnAcc {
    padding: 15px 25px !important;
}

.mediaViewInfo {
    --web-view-name: Web 1903 – 1;
    --web-view-id: adobde;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: adobde;
    /*--bs-body-font-size: 12px;*/
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

.lien_connexion:link {
    text-decoration: none;
    color: white;
}

.lien_rejoindre:link {
    text-decoration: none;
    color: #4f1d4a;
}

.textDescr {
    width: 400px;
    /* width: 377px;*/
    /*height: 107.40983581542969px;*/
    line-height: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}

#adobde {
    position: absolute;
    width: 1903px;
    height: 3799px;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    --web-view-name: Web 1903 – 1;
    --web-view-id: adobde;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.austin-distel-wawEfYdpkag-unsp {
    position: absolute !important;
    width: 1903px !important;
    height: 606.098px !important;
    left: 0px !important;
    top: 197px !important;
    overflow: visible !important;
}

#Groupe_102 {
    position: absolute;
    width: 376px;
    height: 135.853px;
    left: 963px;
    top: 740.016px;
    overflow: visible;
}

#Rectangle_4 {
    fill: rgba(79, 29, 74, 1);
}

.Rectangle_4 {
    position: absolute;
    overflow: visible;
    width: 376px;
    height: 135.853px;
    left: 0px;
    top: 0px;
}

#Friends_such_as_we_desire_are_ {
    left: 50px;
    top: 31px;
    position: absolute;
    overflow: visible;
    width: 276.393px;
    height: 73.40983581542969px;
    line-height: 25px;
    margin-top: -6px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255, 255, 255, 1);
}

#Groupe_57 {
    position: absolute;
    width: 1903px;
    height: 1079.69px;
    left: 0px;
    top: 1285.701px;
    overflow: visible;
}

#Trac_199 {
    fill: rgba(241, 240, 241, 1);
}

.Trac_199 {
    overflow: visible;
    position: absolute;
    width: 1903px;
    height: 1079.69px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#ILS_NOUS_FONT_CONFIANCE {
    left: 717.5px;
    top: 1130.033px;
    position: absolute;
    overflow: visible;
    width: 486px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(105, 192, 172, 1);
}

#Groupe_228 {
    position: absolute;
    width: 1080px;
    height: 606.098px;
    left: 375px;
    top: 1251.213px;
    overflow: visible;
}

#placeholder {
    position: absolute;
    width: 474px;
    height: 606.098px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#placeholder_ {
    position: absolute;
    width: 474px;
    height: 606.099px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Secteur_de_la_Restauration {
    left: 588px;
    top: 167.115px;
    position: absolute;
    overflow: visible;
    width: 493px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(105, 192, 172, 1);
}

#Sous-titre_-_accroche {
    left: 588px;
    top: 136.115px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
}

#Lorem_ipsum_dolor_sit_amet_con {
    left: 588px;
    top: 225.115px;
    position: absolute;
    overflow: visible;
    width: 377px;
    height: 107.40983581542969px;
    line-height: 25px;
    margin-top: -6.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
}

#Composant_6__2 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 588px;
    top: 357.787px;
    overflow: visible;
}

#Rectangle_1 {
    fill: rgba(105, 192, 172, 1);
}

.Rectangle_1 {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Se_connecter {
    left: 44px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Se_connecter {}

#Composant_6__3 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 784px;
    top: 357.885px;
    overflow: visible;
}

#Rectangle_1_bc {
    fill: transparent;
    stroke: rgba(79, 29, 74, 1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_1_bc {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Rejoignez-nous_ {
    left: 30.148px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(79, 29, 74, 1);
}

#Groupe_229 {
    position: absolute;
    width: 964px;
    height: 606.098px;
    left: 571px;
    top: 1878.213px;
    overflow: visible;
}

#placeholder_bf {
    position: absolute;
    width: 474px;
    height: 606.098px;
    left: 490px;
    top: 0px;
    overflow: visible;
}

#placeholder_bg {
    position: absolute;
    width: 474px;
    height: 606.098px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Secteur_Mdical {
    left: 77px;
    top: 167.115px;
    position: absolute;
    overflow: visible;
    width: 300px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(105, 192, 172, 1);
}

#Lorem_ipsum_dolor_sit_amet_con_bi {
    left: 0px;
    top: 225.115px;
    position: absolute;
    overflow: visible;
    width: 377px;
    height: 107.40983581542969px;
    line-height: 25px;
    margin-top: -6.5px;
    text-align: right;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
}

#Composant_6__8 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 357.787px;
    overflow: visible;
}

#Rectangle_1_bk {
    fill: rgba(105, 192, 172, 1);
}

.Rectangle_1_bk {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Se_connecter_bl {
    left: 44px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Composant_6__9 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 196px;
    top: 357.885px;
    overflow: visible;
}

#Rectangle_1_bn {
    fill: transparent;
    stroke: rgba(79, 29, 74, 1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_1_bn {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Rejoignez-nous__bo {
    left: 30.148px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(79, 29, 74, 1);
}

#Sous-titre_-_accroche_bp {
    left: 215px;
    top: 120.754px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
}

#Groupe_230 {
    position: absolute;
    width: 965px;
    height: 606.098px;
    left: 375px;
    top: 2505.213px;
    overflow: visible;
}

#placeholder_br {
    position: absolute;
    width: 474px;
    height: 606.098px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#placeholder_bs {
    position: absolute;
    width: 474px;
    height: 606.098px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Secteur_de_la_Beaut {
    left: 588px;
    top: 167.115px;
    position: absolute;
    overflow: visible;
    width: 378px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(105, 192, 172, 1);
}

#Sous-titre_-_accroche_bu {
    left: 588px;
    top: 136.115px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
}

#Lorem_ipsum_dolor_sit_amet_con_bv {
    left: 588px;
    top: 225.115px;
    position: absolute;
    overflow: visible;
    width: 377px;
    height: 107.40983581542969px;
    line-height: 25px;
    margin-top: -6.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
}

#Composant_6__10 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 585px;
    top: 342.525px;
    overflow: visible;
}

#Rectangle_1_bx {
    fill: rgba(105, 192, 172, 1);
}

.Rectangle_1_bx {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Se_connecter_by {
    left: 44px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Composant_6__11 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 781px;
    top: 342.624px;
    overflow: visible;
}

#Rectangle_1_b {
    fill: transparent;
    stroke: rgba(79, 29, 74, 1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_1_b {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Rejoignez-nous__b {
    left: 30.148px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(79, 29, 74, 1);
    text-decoration: none;
}

#Rejoignez-nous__b:hover {
    background-color: rgba(79, 29, 74, 1);
    color: white;
}

#VOS_AVANTAGES {
    left: 813px;
    top: 3252px;
    position: absolute;
    overflow: visible;
    width: 295px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(79, 29, 74, 1);
}

#placeholder_b {
    display: none;
    position: absolute;
    width: 278px;
    height: 330px;
    left: 1715px;
    top: 3336px;
    overflow: visible;
}

#Composant_10__1 {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 474px;
    top: 3336px;
    overflow: visible;
}

#placeholder_ca {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Prt--porter {
    left: 167px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 143px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 0);
}

#Rectangle_65 {
    fill: transparent;
}

.Rectangle_65 {
    position: absolute;
    overflow: visible;
    width: 239px;
    height: 284px;
    left: 20px;
    top: 23px;
}

#Composant_11__1 {
    position: absolute;
    width: 474px;
    height: 330px;
    left: -18px;
    top: 3336px;
    overflow: visible;
}

#placeholder_cb {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Parcs_de_loisirs {
    left: 160.994px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 0);
}

#Rectangle_66 {
    fill: transparent;
}

.Rectangle_66 {
    position: absolute;
    overflow: visible;
    width: 239px;
    height: 284px;
    left: 20px;
    top: 23px;
}

#Composant_12__1 {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 964px;
    top: 3336px;
    overflow: visible;
}

#placeholder_cd {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Sport__Loisirs {
    left: 166px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 146px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#Trac_203 {
    fill: transparent;
}

.Trac_203 {
    overflow: visible;
    position: absolute;
    width: 239px;
    height: 284px;
    left: 20px;
    top: 23px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#MyTribu {
    left: 375px;
    top: 875.869px;
    position: absolute;
    overflow: visible;
    width: 158px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(79, 29, 74, 1);
}

#Lorem_ipsum_dolor_sit_amet_con_ch {
    left: 375px;
    top: 933.869px;
    position: absolute;
    overflow: visible;
    width: 573px;
    height: 93.8443603515625px;
    line-height: 25px;
    margin-top: -6.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
}

#Composant_6__13 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 1061px;
    top: 951.381px;
    overflow: visible;
}

#Rectangle_1_cj {
    fill: transparent;
    stroke: rgba(79, 29, 74, 1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_1_cj {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Rejoignez-nous__ck {
    left: 30.148px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* color: rgba(79,29,74,1);*/
    color: white;
}

#Trac_206 {
    fill: rgba(255, 255, 255, 1);
}

.Trac_206 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 1464px;
    top: 733.42px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_810 {
    fill: rgba(255, 255, 255, 1);
}

.Trac_810 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 50px;
    /* 77.16px; */
    height: 77.16px;
    left: 1464px;
    top: 733.42px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_207 {
    fill: rgba(255, 255, 255, 1);
}

.Trac_207 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 1366px;
    top: 733.42px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_809 {
    fill: rgba(255, 255, 255, 1);
}

.Trac_809 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 1366px;
    top: 733.42px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Composant_14__1 {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 1454px;
    top: 3336px;
    overflow: visible;
}

#placeholder_cq {
    position: absolute;
    width: 474px;
    height: 330px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Transports {
    left: 181px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#Trac_204 {
    fill: rgba(255, 255, 255, 1);
}

.Trac_204 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 36.419px;
    top: 3471.419px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_205 {
    fill: rgba(255, 255, 255, 1);
}

.Trac_205 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 1824.419px;
    top: 3471.42px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Composant_15__1 {
    position: absolute;
    width: 278px;
    height: 330px;
    left: 1119px;
    top: 3336px;
    display: none;
    overflow: visible;
}

#placeholder_cv {
    position: absolute;
    width: 278px;
    height: 330px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Vie_quotidienne {
    left: 58px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#Composant_16__1 {
    position: absolute;
    width: 278px;
    height: 330px;
    left: 1417px;
    top: 3336px;
    display: none;
    overflow: visible;
}

#placeholder_cy {
    position: absolute;
    width: 278px;
    height: 330px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Beaut__Bien-tre {
    left: 43px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 196px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#diapo-paralax {
    position: absolute;
    width: 1159px;
    height: 470px;
    left: 375px;
    top: 3760px;
    display: none;
    overflow: visible;
}

#Composant_12__3 {
    position: absolute;
    width: 279px;
    height: 328px;
    left: 686px;
    top: 0px;
    overflow: visible;
}

#placeholder_c {
    position: absolute;
    width: 279px;
    height: 328px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Sport__Loisirs_c {
    left: 69px;
    top: 150px;
    position: absolute;
    overflow: visible;
    width: 146px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -1px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#Trac_203_c {
    fill: transparent;
}

.Trac_203_c {
    overflow: visible;
    position: absolute;
    width: 239px;
    height: 284px;
    left: 20px;
    top: 23px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_234 {
    position: absolute;
    width: 376px;
    height: 234.57px;
    left: 0px;
    top: 47px;
    overflow: visible;
}

#VOS_AVANTAGES_c {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 295px;
    white-space: nowrap;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(79, 29, 74, 1);
}

#Lorem_ipsum_dolor_sit_amet_con_c {
    left: 0px;
    top: 58px;
    position: absolute;
    overflow: visible;
    width: 377px;
    height: 107.40983581542969px;
    line-height: 25px;
    margin-top: -6.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
}

#Trac_208 {
    fill: rgba(79, 29, 74, 1);
}

.Trac_208 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 0px;
    top: 175.41px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_209 {
    fill: rgba(79, 29, 74, 1);
}

.Trac_209 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.302));
    overflow: visible;
    position: absolute;
    width: 77.16px;
    height: 77.16px;
    left: 75.16px;
    top: 175.41px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#placeholder_da {
    opacity: 0.6;
    position: absolute;
    width: 179px;
    height: 211px;
    left: 490px;
    top: 59px;
    overflow: visible;
}

#placeholder_db {
    opacity: 0.6;
    position: absolute;
    width: 179px;
    height: 211px;
    left: 980px;
    top: 59px;
    overflow: visible;
}

#Trac_203_dc {
    fill: transparent;
}

.Trac_203_dc {
    overflow: visible;
    position: absolute;
    width: 239px;
    height: 284px;
    left: 609px;
    top: 186px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_1515 {
    position: fixed;
    width: 1903px;
    height: 197px;
    left: 0px;
    top: 0px;
    background-color: white;
}

#Rectangle_1573 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_1573 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 1938px;
    height: 215px;
    left: 0px;
    top: 0px;
}

#Groupe_227 {
    position: absolute;
    width: 325px;
    height: 81px;
    left: 798px;
    top: 75px;
    overflow: visible;
}

#Groupe_226 {
    position: absolute;
    width: 172.09px;
    height: 63.18px;
    left: 76px;
    top: 0px;
    overflow: visible;
}

#Trac_131 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_131 {
    overflow: visible;
    position: absolute;
    width: 24.242px;
    height: 18.773px;
    left: 49.444px;
    top: 0.801px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_132 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_132 {
    overflow: visible;
    position: absolute;
    width: 24.242px;
    height: 18.773px;
    left: 85.67px;
    top: 0.801px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_225 {
    position: absolute;
    width: 172.09px;
    height: 63.18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_112 {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 69.888px;
    top: 17.523px;
    overflow: visible;
}

#Groupe_104 {
    position: absolute;
    width: 5.136px;
    height: 2.63px;
    left: 7.253px;
    top: 14.069px;
    overflow: visible;
}

#Groupe_103 {
    position: absolute;
    width: 5.136px;
    height: 2.63px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_133 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_133 {
    overflow: visible;
    position: absolute;
    width: 5.136px;
    height: 2.63px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_106 {
    position: absolute;
    width: 6.028px;
    height: 4.581px;
    left: 0.801px;
    top: 0.799px;
    overflow: visible;
}

#Groupe_105 {
    position: absolute;
    width: 6.028px;
    height: 4.581px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_134 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_134 {
    overflow: visible;
    position: absolute;
    width: 6.028px;
    height: 4.581px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_108 {
    position: absolute;
    width: 5.977px;
    height: 4.506px;
    left: 12.411px;
    top: 0.799px;
    overflow: visible;
}

#Groupe_107 {
    position: absolute;
    width: 5.977px;
    height: 4.506px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_135 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_135 {
    overflow: visible;
    position: absolute;
    width: 5.977px;
    height: 4.506px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_136 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_136 {
    overflow: visible;
    position: absolute;
    width: 7.213px;
    height: 8.272px;
    left: 0.801px;
    top: 6.292px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_137 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_137 {
    overflow: visible;
    position: absolute;
    width: 7.213px;
    height: 8.272px;
    left: 11.2px;
    top: 6.292px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_111 {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_110 {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_109 {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_138 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_138 {
    overflow: visible;
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_177 {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 70.122px;
    top: 35.106px;
    overflow: visible;
}

#Groupe_119 {
    position: absolute;
    width: 5.784px;
    height: 7.809px;
    left: 6.895px;
    top: 8.783px;
    overflow: visible;
}

#Groupe_114 {
    position: absolute;
    width: 5.264px;
    height: 6.067px;
    left: 0.26px;
    top: 1.743px;
    overflow: visible;
}

#Groupe_113 {
    position: absolute;
    width: 5.264px;
    height: 6.067px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_139 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_139 {
    overflow: visible;
    position: absolute;
    width: 5.264px;
    height: 6.067px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_116 {
    position: absolute;
    width: 5.784px;
    height: 3.891px;
    left: 0px;
    top: 1.382px;
    overflow: visible;
}

#Groupe_115 {
    position: absolute;
    width: 5.784px;
    height: 3.891px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_140 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_140 {
    overflow: visible;
    position: absolute;
    width: 5.784px;
    height: 3.891px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_118 {
    position: absolute;
    width: 4.098px;
    height: 2.984px;
    left: 0.843px;
    top: 0px;
    overflow: visible;
}

#Groupe_117 {
    position: absolute;
    width: 4.098px;
    height: 2.984px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_141 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_141 {
    overflow: visible;
    position: absolute;
    width: 4.098px;
    height: 2.984px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_124 {
    position: absolute;
    width: 14.152px;
    height: 3.184px;
    left: 2.71px;
    top: 6.611px;
    overflow: visible;
}

#Groupe_121 {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_120 {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_142 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_142 {
    overflow: visible;
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_123 {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 10.287px;
    top: 0px;
    overflow: visible;
}

#Groupe_122 {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_143 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_143 {
    overflow: visible;
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_173 {
    position: absolute;
    width: 17.644px;
    height: 17.644px;
    left: 0.751px;
    top: 0.75px;
    overflow: visible;
}

#Groupe_127 {
    position: absolute;
    width: 3.871px;
    height: 2.209px;
    left: 0px;
    top: 2.419px;
    overflow: visible;
}

#Groupe_125 {
    position: absolute;
    width: 3.604px;
    height: 1.942px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_144 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_144 {
    overflow: visible;
    position: absolute;
    width: 3.604px;
    height: 1.942px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_126 {
    position: absolute;
    width: 3.871px;
    height: 2.209px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_145 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_145 {
    overflow: visible;
    position: absolute;
    width: 3.871px;
    height: 2.209px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_130 {
    position: absolute;
    width: 3.529px;
    height: 3.8px;
    left: 1.158px;
    top: 0.49px;
    overflow: visible;
}

#Groupe_128 {
    position: absolute;
    width: 3.262px;
    height: 3.533px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_146 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_146 {
    overflow: visible;
    position: absolute;
    width: 3.262px;
    height: 3.533px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_129 {
    position: absolute;
    width: 3.529px;
    height: 3.8px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_147 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_147 {
    overflow: visible;
    position: absolute;
    width: 3.529px;
    height: 3.8px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_133 {
    position: absolute;
    width: 2.724px;
    height: 3.788px;
    left: 3.606px;
    top: 0.001px;
    overflow: visible;
}

#Groupe_131 {
    position: absolute;
    width: 2.458px;
    height: 3.521px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_148 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_148 {
    overflow: visible;
    position: absolute;
    width: 2.458px;
    height: 3.521px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_132 {
    position: absolute;
    width: 2.724px;
    height: 3.788px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_149 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_149 {
    overflow: visible;
    position: absolute;
    width: 2.724px;
    height: 3.788px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_136 {
    position: absolute;
    width: 2.313px;
    height: 3.476px;
    left: 5.947px;
    top: 0.001px;
    overflow: visible;
}

#Groupe_134 {
    position: absolute;
    width: 2.046px;
    height: 3.21px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_150 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_150 {
    overflow: visible;
    position: absolute;
    width: 2.046px;
    height: 3.21px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_135 {
    position: absolute;
    width: 2.313px;
    height: 3.476px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_151 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_151 {
    overflow: visible;
    position: absolute;
    width: 2.313px;
    height: 3.476px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_139 {
    position: absolute;
    width: 3.565px;
    height: 2.552px;
    left: 0px;
    top: 9.658px;
    overflow: visible;
}

#Groupe_137 {
    position: absolute;
    width: 3.298px;
    height: 2.285px;
    left: 0.134px;
    top: 0.134px;
    overflow: visible;
}

#Trac_152 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_152 {
    overflow: visible;
    position: absolute;
    width: 3.298px;
    height: 2.285px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_138 {
    position: absolute;
    width: 3.565px;
    height: 2.552px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_153 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_153 {
    overflow: visible;
    position: absolute;
    width: 3.565px;
    height: 2.552px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_142 {
    position: absolute;
    width: 3.963px;
    height: 3.525px;
    left: 0.029px;
    top: 11.673px;
    overflow: visible;
}

#Groupe_140 {
    position: absolute;
    width: 3.696px;
    height: 3.258px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_154 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_154 {
    overflow: visible;
    position: absolute;
    width: 3.696px;
    height: 3.258px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_141 {
    position: absolute;
    width: 3.963px;
    height: 3.525px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_155 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_155 {
    overflow: visible;
    position: absolute;
    width: 3.963px;
    height: 3.525px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_145 {
    position: absolute;
    width: 2.82px;
    height: 4.517px;
    left: 1.688px;
    top: 12.955px;
    overflow: visible;
}

#Groupe_143 {
    position: absolute;
    width: 2.553px;
    height: 4.25px;
    left: 0.133px;
    top: 0.134px;
    overflow: visible;
}

#Trac_156 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_156 {
    overflow: visible;
    position: absolute;
    width: 2.553px;
    height: 4.25px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_144 {
    position: absolute;
    width: 2.82px;
    height: 4.517px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_157 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_157 {
    overflow: visible;
    position: absolute;
    width: 2.82px;
    height: 4.517px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_148 {
    position: absolute;
    width: 2.079px;
    height: 3.962px;
    left: 4.043px;
    top: 13.681px;
    overflow: visible;
}

#Groupe_146 {
    position: absolute;
    width: 1.812px;
    height: 3.696px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_158 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_158 {
    overflow: visible;
    position: absolute;
    width: 1.812px;
    height: 3.696px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_147 {
    position: absolute;
    width: 2.079px;
    height: 3.962px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_159 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_159 {
    overflow: visible;
    position: absolute;
    width: 2.079px;
    height: 3.962px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_151 {
    position: absolute;
    width: 2.3px;
    height: 3.478px;
    left: 9.394px;
    top: 0px;
    overflow: visible;
}

#Groupe_149 {
    position: absolute;
    width: 2.033px;
    height: 3.211px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_160 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_160 {
    overflow: visible;
    position: absolute;
    width: 2.033px;
    height: 3.211px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_150 {
    position: absolute;
    width: 2.3px;
    height: 3.478px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_161 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_161 {
    overflow: visible;
    position: absolute;
    width: 2.3px;
    height: 3.478px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_154 {
    position: absolute;
    width: 1.337px;
    height: 2.94px;
    left: 12.582px;
    top: 14.704px;
    overflow: visible;
}

#Groupe_152 {
    position: absolute;
    width: 1.071px;
    height: 2.673px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_162 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_162 {
    overflow: visible;
    position: absolute;
    width: 1.07px;
    height: 2.673px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_153 {
    position: absolute;
    width: 1.337px;
    height: 2.94px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_163 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_163 {
    overflow: visible;
    position: absolute;
    width: 1.337px;
    height: 2.94px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_157 {
    position: absolute;
    width: 2.725px;
    height: 3.786px;
    left: 11.324px;
    top: 0.001px;
    overflow: visible;
}

#Groupe_155 {
    position: absolute;
    width: 2.459px;
    height: 3.519px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_164 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_164 {
    overflow: visible;
    position: absolute;
    width: 2.459px;
    height: 3.519px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_156 {
    position: absolute;
    width: 2.725px;
    height: 3.786px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_165 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_165 {
    overflow: visible;
    position: absolute;
    width: 2.725px;
    height: 3.786px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_160 {
    position: absolute;
    width: 2.438px;
    height: 3.846px;
    left: 13.644px;
    top: 13.627px;
    overflow: visible;
}

#Groupe_158 {
    position: absolute;
    width: 2.17px;
    height: 3.579px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_166 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_166 {
    overflow: visible;
    position: absolute;
    width: 2.17px;
    height: 3.579px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_159 {
    position: absolute;
    width: 2.438px;
    height: 3.846px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_167 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_167 {
    overflow: visible;
    position: absolute;
    width: 2.438px;
    height: 3.846px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_163 {
    position: absolute;
    width: 3.527px;
    height: 3.791px;
    left: 12.965px;
    top: 0.494px;
    overflow: visible;
}

#Groupe_161 {
    position: absolute;
    width: 3.26px;
    height: 3.524px;
    left: 0.134px;
    top: 0.134px;
    overflow: visible;
}

#Trac_168 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_168 {
    overflow: visible;
    position: absolute;
    width: 3.26px;
    height: 3.524px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_162 {
    position: absolute;
    width: 3.527px;
    height: 3.791px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_169 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_169 {
    overflow: visible;
    position: absolute;
    width: 3.527px;
    height: 3.791px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_166 {
    position: absolute;
    width: 3.339px;
    height: 3.231px;
    left: 14.254px;
    top: 12.268px;
    overflow: visible;
}

#Groupe_164 {
    position: absolute;
    width: 3.071px;
    height: 2.964px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_170 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_170 {
    overflow: visible;
    position: absolute;
    width: 3.071px;
    height: 2.964px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_165 {
    position: absolute;
    width: 3.339px;
    height: 3.231px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_171 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_171 {
    overflow: visible;
    position: absolute;
    width: 3.339px;
    height: 3.231px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_169 {
    position: absolute;
    width: 3.876px;
    height: 2.228px;
    left: 13.768px;
    top: 2.419px;
    overflow: visible;
}

#Groupe_167 {
    position: absolute;
    width: 3.609px;
    height: 1.961px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_172 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_172 {
    overflow: visible;
    position: absolute;
    width: 3.609px;
    height: 1.961px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_168 {
    position: absolute;
    width: 3.876px;
    height: 2.228px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_173 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_173 {
    overflow: visible;
    position: absolute;
    width: 3.876px;
    height: 2.228px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_172 {
    position: absolute;
    width: 2.873px;
    height: 1.268px;
    left: 14.77px;
    top: 9.882px;
    overflow: visible;
}

#Groupe_170 {
    position: absolute;
    width: 2.606px;
    height: 1.002px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_174 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_174 {
    overflow: visible;
    position: absolute;
    width: 2.606px;
    height: 1.002px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_171 {
    position: absolute;
    width: 2.873px;
    height: 1.268px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_175 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_175 {
    overflow: visible;
    position: absolute;
    width: 2.873px;
    height: 1.268px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_176 {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_175 {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_174 {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_176 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_176 {
    overflow: visible;
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_205 {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 69.908px;
    top: 0px;
    overflow: visible;
}

#Groupe_180 {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_179 {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_178 {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_177 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_177 {
    overflow: visible;
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_182 {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 2.145px;
    top: 4.616px;
    overflow: visible;
}

#Groupe_181 {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_178 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_178 {
    overflow: visible;
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_184 {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 9.278px;
    top: 4.616px;
    overflow: visible;
}

#Groupe_183 {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_179 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_179 {
    overflow: visible;
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_186 {
    position: absolute;
    width: 2.437px;
    height: 1.856px;
    left: 8.355px;
    top: 11.411px;
    overflow: visible;
}

#Groupe_185 {
    position: absolute;
    width: 2.437px;
    height: 1.856px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_180 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_180 {
    overflow: visible;
    position: absolute;
    width: 2.437px;
    height: 1.856px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_188 {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0.801px;
    top: 14.434px;
    overflow: visible;
}

#Groupe_187 {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_181 {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(0, 0, 0, 1);
    stroke-width: 2.1179699897766113px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 10;
    shape-rendering: auto;
}

.Trac_181 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_190 {
    position: absolute;
    width: 7.604px;
    height: 2px;
    left: 1.616px;
    top: 4.335px;
    overflow: visible;
}

#Groupe_189 {
    position: absolute;
    width: 7.604px;
    height: 2px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_182 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_182 {
    overflow: visible;
    position: absolute;
    width: 7.604px;
    height: 2px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_192 {
    position: absolute;
    width: 7.603px;
    height: 2px;
    left: 9.928px;
    top: 4.335px;
    overflow: visible;
}

#Groupe_191 {
    position: absolute;
    width: 7.603px;
    height: 2px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_183 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_183 {
    overflow: visible;
    position: absolute;
    width: 7.603px;
    height: 2px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_194 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 4.262px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_193 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_184 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_184 {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_196 {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 5.589px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_195 {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_185 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_185 {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_198 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 6.917px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_197 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_186 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_186 {
    overflow: visible;
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_200 {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 10.588px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_199 {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_187 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_187 {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_202 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 11.916px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_201 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_188 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_188 {
    overflow: visible;
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_204 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 13.243px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_203 {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_189 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_189 {
    overflow: visible;
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_218 {
    position: absolute;
    width: 172.09px;
    height: 45.182px;
    left: 0px;
    top: 17.999px;
    overflow: visible;
}

#Groupe_207 {
    position: absolute;
    width: 33.78px;
    height: 34.656px;
    left: 0px;
    top: 1.197px;
    overflow: visible;
}

#Groupe_206 {
    position: absolute;
    width: 33.78px;
    height: 34.656px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_190 {
    fill: rgba(105, 191, 171, 1);
}

.Trac_190 {
    overflow: visible;
    position: absolute;
    width: 33.78px;
    height: 34.656px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_209 {
    position: absolute;
    width: 22.065px;
    height: 28.772px;
    left: 39.066px;
    top: 16.409px;
    overflow: visible;
}

#Groupe_208 {
    position: absolute;
    width: 22.065px;
    height: 28.772px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_191 {
    fill: rgba(105, 191, 171, 1);
}

.Trac_191 {
    overflow: visible;
    position: absolute;
    width: 22.065px;
    height: 28.773px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_211 {
    position: absolute;
    width: 12.639px;
    height: 20.314px;
    left: 95.428px;
    top: 15.538px;
    overflow: visible;
}

#Groupe_210 {
    position: absolute;
    width: 12.639px;
    height: 20.314px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_192 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_192 {
    overflow: visible;
    position: absolute;
    width: 12.639px;
    height: 20.314px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_213 {
    position: absolute;
    width: 5.884px;
    height: 29.142px;
    left: 113.125px;
    top: 6.71px;
    overflow: visible;
}

#Groupe_212 {
    position: absolute;
    width: 5.884px;
    height: 29.142px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_193 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_193 {
    overflow: visible;
    position: absolute;
    width: 5.884px;
    height: 29.142px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_215 {
    position: absolute;
    width: 22.383px;
    height: 36.358px;
    left: 125.578px;
    top: 0px;
    overflow: visible;
}

#Groupe_214 {
    position: absolute;
    width: 22.383px;
    height: 36.358px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_194 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_194 {
    overflow: visible;
    position: absolute;
    width: 22.383px;
    height: 36.358px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_217 {
    position: absolute;
    width: 18.706px;
    height: 20.27px;
    left: 153.384px;
    top: 16.409px;
    overflow: visible;
}

#Groupe_216 {
    position: absolute;
    width: 18.706px;
    height: 20.27px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_195 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_195 {
    overflow: visible;
    position: absolute;
    width: 18.706px;
    height: 20.27px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_220 {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 73.877px;
    top: 6.439px;
    overflow: visible;
}

#Groupe_219 {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_196 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_196 {
    overflow: visible;
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_222 {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 81.01px;
    top: 6.439px;
    overflow: visible;
}

#Groupe_221 {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_197 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_197 {
    overflow: visible;
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_224 {
    position: absolute;
    width: 6.013px;
    height: 1.913px;
    left: 76.318px;
    top: 2.856px;
    overflow: visible;
}

#Groupe_223 {
    position: absolute;
    width: 6.013px;
    height: 1.913px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_198 {
    fill: rgba(79, 30, 74, 1);
}

.Trac_198 {
    overflow: visible;
    position: absolute;
    width: 6.013px;
    height: 1.913px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Le_CE_qui_rassemble_ceux_qui_v {
    left: 0px;
    top: 63px;
    position: absolute;
    overflow: visible;
    width: 326px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4px;
    text-align: left;
    font-family: 'PoetsenOne';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(79, 29, 74, 1);
}

#Rectangle_68 {
    fill: rgba(79, 29, 74, 1);
}

.Rectangle_68 {
    position: absolute;
    overflow: visible;
    width: 1903px;
    height: 50px;
    left: 0px;
    top: 0px;
}

#Qui_sommes-nous_ {
    left: 375px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 144px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Nous_contacter {
    left: 1257px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Les-corps-de-metiers {
    left: 963px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 146px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Nos_partenaires {
    left: 669px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Composant_6__24 {
    position: absolute;
    width: 180px;
    height: 58.82px;
    left: 1355px;
    top: 94.18px;
    overflow: visible;
    cursor: pointer;
    --web-animation: fadein 0.30000001192092896s ease-out;
    --web-action-type: page;
    --web-action-target: popin.html;
}

#Rectangle_1_iz {
    fill: rgba(105, 192, 172, 1);
}

.Rectangle_1_iz {
    position: absolute;
    overflow: visible;
    width: 180px;
    height: 58.82px;
    left: 0px;
    top: 0px;
}

#Mon_compte {
    left: 46px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Groupe_1517 {
    position: absolute;
    width: 1903px;
    height: 100px;
    left: 0px;
    top: 3700px;
    overflow: visible;
}

#Rectangle_1574 {
    fill: rgba(79, 29, 74, 1);
}

.Rectangle_1574 {
    position: absolute;
    overflow: visible;
    width: 1903px;
    height: 100px;
    left: 0px;
    top: 0px;
}

#Groupe_1516 {
    position: absolute;
    width: 707px;
    height: 65px;
    left: 669px;
    top: 18px;
    overflow: visible;
}

#Nos_partenaires_i {
    left: 588px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Qui_sommes-nous__i {
    left: 294px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 144px;
    white-space: nowrap;
    cursor: pointer;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Le_CE_Interprofessionnel {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 168px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Mentions_lgales {
    left: 588px;
    top: 23px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    --web-animation: fadein 0.30000001192092896s ease-out;
    --web-action-type: page;
    --web-action-target: page_statique__mention_l_gale.html;
    cursor: pointer;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Les-corps-de-metiers_i {
    left: 294px;
    top: 23px;
    position: absolute;
    overflow: visible;
    width: 146px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Conditions_gnrales_de_vente {
    left: 0px;
    top: 23px;
    position: absolute;
    overflow: visible;
    width: 217px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Nous_contacter_ja {
    left: 294px;
    top: 45px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    cursor: pointer;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Protection_des_donnes {
    left: 0px;
    top: 45px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -4px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Groupe_233 {
    position: absolute;
    width: 172.09px;
    height: 63.18px;
    left: 375px;
    top: 18px;
    overflow: visible;
}

#Trac_131_jd {
    fill: rgba(255, 255, 255, 1);
}

.Trac_131_jd {
    overflow: visible;
    position: absolute;
    width: 24.242px;
    height: 18.773px;
    left: 49.444px;
    top: 0.801px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_132_je {
    fill: rgba(255, 255, 255, 1);
}

.Trac_132_je {
    overflow: visible;
    position: absolute;
    width: 24.242px;
    height: 18.773px;
    left: 85.67px;
    top: 0.801px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_225_jf {
    position: absolute;
    width: 172.09px;
    height: 63.18px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_112_jg {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 69.888px;
    top: 17.523px;
    overflow: visible;
}

#Groupe_104_jh {
    position: absolute;
    width: 5.136px;
    height: 2.63px;
    left: 7.253px;
    top: 14.069px;
    overflow: visible;
}

#Groupe_103_ji {
    position: absolute;
    width: 5.136px;
    height: 2.63px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_133_jj {
    fill: rgba(255, 255, 255, 1);
}

.Trac_133_jj {
    overflow: visible;
    position: absolute;
    width: 5.136px;
    height: 2.63px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_106_jk {
    position: absolute;
    width: 6.028px;
    height: 4.581px;
    left: 0.801px;
    top: 0.799px;
    overflow: visible;
}

#Groupe_105_jl {
    position: absolute;
    width: 6.028px;
    height: 4.581px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_134_jm {
    fill: rgba(255, 255, 255, 1);
}

.Trac_134_jm {
    overflow: visible;
    position: absolute;
    width: 6.028px;
    height: 4.581px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_108_jn {
    position: absolute;
    width: 5.977px;
    height: 4.506px;
    left: 12.411px;
    top: 0.799px;
    overflow: visible;
}

#Groupe_107_jo {
    position: absolute;
    width: 5.977px;
    height: 4.506px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_135_jp {
    fill: rgba(255, 255, 255, 1);
}

.Trac_135_jp {
    overflow: visible;
    position: absolute;
    width: 5.977px;
    height: 4.506px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_136_jq {
    fill: rgba(255, 255, 255, 1);
}

.Trac_136_jq {
    overflow: visible;
    position: absolute;
    width: 7.213px;
    height: 8.272px;
    left: 0.801px;
    top: 6.292px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Trac_137_jr {
    fill: rgba(255, 255, 255, 1);
}

.Trac_137_jr {
    overflow: visible;
    position: absolute;
    width: 7.213px;
    height: 8.272px;
    left: 11.2px;
    top: 6.292px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_111_js {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_110_jt {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_109_ju {
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_138_jv {
    fill: rgba(255, 255, 255, 1);
}

.Trac_138_jv {
    overflow: visible;
    position: absolute;
    width: 19.186px;
    height: 19.185px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_177_jw {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 70.122px;
    top: 35.106px;
    overflow: visible;
}

#Groupe_119_jx {
    position: absolute;
    width: 5.784px;
    height: 7.809px;
    left: 6.895px;
    top: 8.783px;
    overflow: visible;
}

#Groupe_114_jy {
    position: absolute;
    width: 5.264px;
    height: 6.067px;
    left: 0.26px;
    top: 1.743px;
    overflow: visible;
}

#Groupe_113_jz {
    position: absolute;
    width: 5.264px;
    height: 6.067px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_139_j {
    fill: rgba(255, 255, 255, 1);
}

.Trac_139_j {
    overflow: visible;
    position: absolute;
    width: 5.264px;
    height: 6.067px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_116_j {
    position: absolute;
    width: 5.784px;
    height: 3.891px;
    left: 0px;
    top: 1.382px;
    overflow: visible;
}

#Groupe_115_j {
    position: absolute;
    width: 5.784px;
    height: 3.891px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_140_j {
    fill: rgba(255, 255, 255, 1);
}

.Trac_140_j {
    overflow: visible;
    position: absolute;
    width: 5.784px;
    height: 3.891px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_118_j {
    position: absolute;
    width: 4.098px;
    height: 2.984px;
    left: 0.843px;
    top: 0px;
    overflow: visible;
}

#Groupe_117_j {
    position: absolute;
    width: 4.098px;
    height: 2.984px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_141_j {
    fill: rgba(255, 255, 255, 1);
}

.Trac_141_j {
    overflow: visible;
    position: absolute;
    width: 4.098px;
    height: 2.984px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_124_j {
    position: absolute;
    width: 14.152px;
    height: 3.184px;
    left: 2.71px;
    top: 6.611px;
    overflow: visible;
}

#Groupe_121_j {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_120_j {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_142_ka {
    fill: rgba(255, 255, 255, 1);
}

.Trac_142_ka {
    overflow: visible;
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_123_kb {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 10.288px;
    top: 0px;
    overflow: visible;
}

#Groupe_122_kc {
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_143_kd {
    fill: rgba(255, 255, 255, 1);
}

.Trac_143_kd {
    overflow: visible;
    position: absolute;
    width: 3.865px;
    height: 3.184px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_173_ke {
    position: absolute;
    width: 17.644px;
    height: 17.644px;
    left: 0.751px;
    top: 0.75px;
    overflow: visible;
}

#Groupe_127_kf {
    position: absolute;
    width: 3.871px;
    height: 2.209px;
    left: 0px;
    top: 2.419px;
    overflow: visible;
}

#Groupe_125_kg {
    position: absolute;
    width: 3.604px;
    height: 1.942px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_144_kh {
    fill: rgba(255, 255, 255, 1);
}

.Trac_144_kh {
    overflow: visible;
    position: absolute;
    width: 3.604px;
    height: 1.942px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_126_ki {
    position: absolute;
    width: 3.871px;
    height: 2.209px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_145_kj {
    fill: rgba(255, 255, 255, 1);
}

.Trac_145_kj {
    overflow: visible;
    position: absolute;
    width: 3.871px;
    height: 2.209px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_130_kk {
    position: absolute;
    width: 3.529px;
    height: 3.8px;
    left: 1.158px;
    top: 0.49px;
    overflow: visible;
}

#Groupe_128_kl {
    position: absolute;
    width: 3.262px;
    height: 3.533px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_146_km {
    fill: rgba(255, 255, 255, 1);
}

.Trac_146_km {
    overflow: visible;
    position: absolute;
    width: 3.262px;
    height: 3.533px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_129_kn {
    position: absolute;
    width: 3.529px;
    height: 3.8px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_147_ko {
    fill: rgba(255, 255, 255, 1);
}

.Trac_147_ko {
    overflow: visible;
    position: absolute;
    width: 3.529px;
    height: 3.8px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_133_kp {
    position: absolute;
    width: 2.724px;
    height: 3.788px;
    left: 3.606px;
    top: 0.001px;
    overflow: visible;
}

#Groupe_131_kq {
    position: absolute;
    width: 2.458px;
    height: 3.521px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_148_kr {
    fill: rgba(255, 255, 255, 1);
}

.Trac_148_kr {
    overflow: visible;
    position: absolute;
    width: 2.458px;
    height: 3.521px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_132_ks {
    position: absolute;
    width: 2.724px;
    height: 3.788px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_149_kt {
    fill: rgba(255, 255, 255, 1);
}

.Trac_149_kt {
    overflow: visible;
    position: absolute;
    width: 2.724px;
    height: 3.788px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_136_ku {
    position: absolute;
    width: 2.313px;
    height: 3.476px;
    left: 5.947px;
    top: 0.001px;
    overflow: visible;
}

#Groupe_134_kv {
    position: absolute;
    width: 2.046px;
    height: 3.21px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_150_kw {
    fill: rgba(255, 255, 255, 1);
}

.Trac_150_kw {
    overflow: visible;
    position: absolute;
    width: 2.046px;
    height: 3.21px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_135_kx {
    position: absolute;
    width: 2.313px;
    height: 3.476px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_151_ky {
    fill: rgba(255, 255, 255, 1);
}

.Trac_151_ky {
    overflow: visible;
    position: absolute;
    width: 2.313px;
    height: 3.476px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_139_kz {
    position: absolute;
    width: 3.565px;
    height: 2.552px;
    left: 0px;
    top: 9.658px;
    overflow: visible;
}

#Groupe_137_k {
    position: absolute;
    width: 3.298px;
    height: 2.285px;
    left: 0.134px;
    top: 0.134px;
    overflow: visible;
}

#Trac_152_k {
    fill: rgba(255, 255, 255, 1);
}

.Trac_152_k {
    overflow: visible;
    position: absolute;
    width: 3.298px;
    height: 2.285px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_138_k {
    position: absolute;
    width: 3.565px;
    height: 2.552px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_153_k {
    fill: rgba(255, 255, 255, 1);
}

.Trac_153_k {
    overflow: visible;
    position: absolute;
    width: 3.565px;
    height: 2.552px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_142_k {
    position: absolute;
    width: 3.963px;
    height: 3.525px;
    left: 0.029px;
    top: 11.673px;
    overflow: visible;
}

#Groupe_140_k {
    position: absolute;
    width: 3.696px;
    height: 3.258px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_154_k {
    fill: rgba(255, 255, 255, 1);
}

.Trac_154_k {
    overflow: visible;
    position: absolute;
    width: 3.696px;
    height: 3.258px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_141_k {
    position: absolute;
    width: 3.963px;
    height: 3.525px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_155_k {
    fill: rgba(255, 255, 255, 1);
}

.Trac_155_k {
    overflow: visible;
    position: absolute;
    width: 3.963px;
    height: 3.525px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_145_k {
    position: absolute;
    width: 2.82px;
    height: 4.517px;
    left: 1.688px;
    top: 12.955px;
    overflow: visible;
}

#Groupe_143_la {
    position: absolute;
    width: 2.553px;
    height: 4.25px;
    left: 0.133px;
    top: 0.134px;
    overflow: visible;
}

#Trac_156_lb {
    fill: rgba(255, 255, 255, 1);
}

.Trac_156_lb {
    overflow: visible;
    position: absolute;
    width: 2.553px;
    height: 4.25px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_144_lc {
    position: absolute;
    width: 2.82px;
    height: 4.517px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_157_ld {
    fill: rgba(255, 255, 255, 1);
}

.Trac_157_ld {
    overflow: visible;
    position: absolute;
    width: 2.82px;
    height: 4.517px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_148_le {
    position: absolute;
    width: 2.079px;
    height: 3.962px;
    left: 4.044px;
    top: 13.681px;
    overflow: visible;
}

#Groupe_146_lf {
    position: absolute;
    width: 1.812px;
    height: 3.696px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_158_lg {
    fill: rgba(255, 255, 255, 1);
}

.Trac_158_lg {
    overflow: visible;
    position: absolute;
    width: 1.812px;
    height: 3.696px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_147_lh {
    position: absolute;
    width: 2.079px;
    height: 3.962px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_159_li {
    fill: rgba(255, 255, 255, 1);
}

.Trac_159_li {
    overflow: visible;
    position: absolute;
    width: 2.079px;
    height: 3.962px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_151_lj {
    position: absolute;
    width: 2.3px;
    height: 3.478px;
    left: 9.394px;
    top: 0px;
    overflow: visible;
}

#Groupe_149_lk {
    position: absolute;
    width: 2.033px;
    height: 3.211px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_160_ll {
    fill: rgba(255, 255, 255, 1);
}

.Trac_160_ll {
    overflow: visible;
    position: absolute;
    width: 2.033px;
    height: 3.211px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_150_lm {
    position: absolute;
    width: 2.3px;
    height: 3.478px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_161_ln {
    fill: rgba(255, 255, 255, 1);
}

.Trac_161_ln {
    overflow: visible;
    position: absolute;
    width: 2.3px;
    height: 3.478px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_154_lo {
    position: absolute;
    width: 1.337px;
    height: 2.94px;
    left: 12.582px;
    top: 14.704px;
    overflow: visible;
}

#Groupe_152_lp {
    position: absolute;
    width: 1.071px;
    height: 2.673px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_162_lq {
    fill: rgba(255, 255, 255, 1);
}

.Trac_162_lq {
    overflow: visible;
    position: absolute;
    width: 1.071px;
    height: 2.673px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_153_lr {
    position: absolute;
    width: 1.337px;
    height: 2.94px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_163_ls {
    fill: rgba(255, 255, 255, 1);
}

.Trac_163_ls {
    overflow: visible;
    position: absolute;
    width: 1.337px;
    height: 2.94px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_157_lt {
    position: absolute;
    width: 2.725px;
    height: 3.786px;
    left: 11.324px;
    top: 0.001px;
    overflow: visible;
}

#Groupe_155_lu {
    position: absolute;
    width: 2.459px;
    height: 3.519px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_164_lv {
    fill: rgba(255, 255, 255, 1);
}

.Trac_164_lv {
    overflow: visible;
    position: absolute;
    width: 2.459px;
    height: 3.519px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_156_lw {
    position: absolute;
    width: 2.725px;
    height: 3.786px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_165_lx {
    fill: rgba(255, 255, 255, 1);
}

.Trac_165_lx {
    overflow: visible;
    position: absolute;
    width: 2.725px;
    height: 3.785px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_160_ly {
    position: absolute;
    width: 2.438px;
    height: 3.846px;
    left: 13.644px;
    top: 13.627px;
    overflow: visible;
}

#Groupe_158_lz {
    position: absolute;
    width: 2.17px;
    height: 3.579px;
    left: 0.134px;
    top: 0.134px;
    overflow: visible;
}

#Trac_166_l {
    fill: rgba(255, 255, 255, 1);
}

.Trac_166_l {
    overflow: visible;
    position: absolute;
    width: 2.17px;
    height: 3.579px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_159_l {
    position: absolute;
    width: 2.438px;
    height: 3.846px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_167_l {
    fill: rgba(255, 255, 255, 1);
}

.Trac_167_l {
    overflow: visible;
    position: absolute;
    width: 2.438px;
    height: 3.846px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_163_l {
    position: absolute;
    width: 3.527px;
    height: 3.791px;
    left: 12.965px;
    top: 0.494px;
    overflow: visible;
}

#Groupe_161_l {
    position: absolute;
    width: 3.26px;
    height: 3.524px;
    left: 0.134px;
    top: 0.134px;
    overflow: visible;
}

#Trac_168_l {
    fill: rgba(255, 255, 255, 1);
}

.Trac_168_l {
    overflow: visible;
    position: absolute;
    width: 3.26px;
    height: 3.524px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_162_l {
    position: absolute;
    width: 3.527px;
    height: 3.791px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_169_l {
    fill: rgba(255, 255, 255, 1);
}

.Trac_169_l {
    overflow: visible;
    position: absolute;
    width: 3.527px;
    height: 3.791px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_166_l {
    position: absolute;
    width: 3.339px;
    height: 3.231px;
    left: 14.254px;
    top: 12.269px;
    overflow: visible;
}

#Groupe_164_l {
    position: absolute;
    width: 3.071px;
    height: 2.964px;
    left: 0.134px;
    top: 0.133px;
    overflow: visible;
}

#Trac_170_ma {
    fill: rgba(255, 255, 255, 1);
}

.Trac_170_ma {
    overflow: visible;
    position: absolute;
    width: 3.071px;
    height: 2.964px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_165_mb {
    position: absolute;
    width: 3.339px;
    height: 3.231px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_171_mc {
    fill: rgba(255, 255, 255, 1);
}

.Trac_171_mc {
    overflow: visible;
    position: absolute;
    width: 3.339px;
    height: 3.231px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_169_md {
    position: absolute;
    width: 3.876px;
    height: 2.228px;
    left: 13.768px;
    top: 2.419px;
    overflow: visible;
}

#Groupe_167_me {
    position: absolute;
    width: 3.609px;
    height: 1.961px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_172_mf {
    fill: rgba(255, 255, 255, 1);
}

.Trac_172_mf {
    overflow: visible;
    position: absolute;
    width: 3.609px;
    height: 1.961px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_168_mg {
    position: absolute;
    width: 3.876px;
    height: 2.228px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_173_mh {
    fill: rgba(255, 255, 255, 1);
}

.Trac_173_mh {
    overflow: visible;
    position: absolute;
    width: 3.876px;
    height: 2.228px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_172_mi {
    position: absolute;
    width: 2.873px;
    height: 1.268px;
    left: 14.77px;
    top: 9.882px;
    overflow: visible;
}

#Groupe_170_mj {
    position: absolute;
    width: 2.606px;
    height: 1.002px;
    left: 0.133px;
    top: 0.133px;
    overflow: visible;
}

#Trac_174_mk {
    fill: rgba(255, 255, 255, 1);
}

.Trac_174_mk {
    overflow: visible;
    position: absolute;
    width: 2.606px;
    height: 1.001px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_171_ml {
    position: absolute;
    width: 2.873px;
    height: 1.268px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_175_mm {
    fill: rgba(255, 255, 255, 1);
}

.Trac_175_mm {
    overflow: visible;
    position: absolute;
    width: 2.873px;
    height: 1.269px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_176_mn {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_175_mo {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_174_mp {
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_176_mq {
    fill: rgba(255, 255, 255, 1);
}

.Trac_176_mq {
    overflow: visible;
    position: absolute;
    width: 19.145px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_205_mr {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 69.908px;
    top: 0px;
    overflow: visible;
}

#Groupe_180_ms {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_179_mt {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Groupe_178_mu {
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_177_mv {
    fill: rgba(255, 255, 255, 1);
}

.Trac_177_mv {
    overflow: visible;
    position: absolute;
    width: 19.146px;
    height: 19.145px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_182_mw {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 2.145px;
    top: 4.615px;
    overflow: visible;
}

#Groupe_181_mx {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_178_my {
    fill: rgba(255, 255, 255, 1);
}

.Trac_178_my {
    overflow: visible;
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_184_mz {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 9.278px;
    top: 4.615px;
    overflow: visible;
}

#Groupe_183_m {
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_179_m {
    fill: rgba(255, 255, 255, 1);
}

.Trac_179_m {
    overflow: visible;
    position: absolute;
    width: 7.723px;
    height: 7.723px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_186_m {
    position: absolute;
    width: 2.437px;
    height: 1.856px;
    left: 8.355px;
    top: 11.41px;
    overflow: visible;
}

#Groupe_185_m {
    position: absolute;
    width: 2.437px;
    height: 1.856px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_180_m {
    fill: rgba(255, 255, 255, 1);
}

.Trac_180_m {
    overflow: visible;
    position: absolute;
    width: 2.437px;
    height: 1.855px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_188_m {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0.801px;
    top: 14.434px;
    overflow: visible;
}

#Groupe_187_m {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_181_m {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(0, 0, 0, 1);
    stroke-width: 2.1179699897766113px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 10;
    shape-rendering: auto;
}

.Trac_181_m {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_190_m {
    position: absolute;
    width: 7.604px;
    height: 2px;
    left: 1.616px;
    top: 4.335px;
    overflow: visible;
}

#Groupe_189_m {
    position: absolute;
    width: 7.604px;
    height: 2px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_182_na {
    fill: rgba(255, 255, 255, 1);
}

.Trac_182_na {
    overflow: visible;
    position: absolute;
    width: 7.604px;
    height: 2px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_192_nb {
    position: absolute;
    width: 7.603px;
    height: 2px;
    left: 9.928px;
    top: 4.335px;
    overflow: visible;
}

#Groupe_191_nc {
    position: absolute;
    width: 7.603px;
    height: 2px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_183_nd {
    fill: rgba(255, 255, 255, 1);
}

.Trac_183_nd {
    overflow: visible;
    position: absolute;
    width: 7.603px;
    height: 2px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_194_ne {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 4.262px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_193_nf {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_184_ng {
    fill: rgba(255, 255, 255, 1);
}

.Trac_184_ng {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_196_nh {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 5.589px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_195_ni {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_185_nj {
    fill: rgba(255, 255, 255, 1);
}

.Trac_185_nj {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_198_nk {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 6.917px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_197_nl {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_186_nm {
    fill: rgba(255, 255, 255, 1);
}

.Trac_186_nm {
    overflow: visible;
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_200_nn {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 10.588px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_199_no {
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_187_np {
    fill: rgba(255, 255, 255, 1);
}

.Trac_187_np {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_202_nq {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 11.916px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_201_nr {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_188_ns {
    fill: rgba(255, 255, 255, 1);
}

.Trac_188_ns {
    overflow: visible;
    position: absolute;
    width: 1.641px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_204_nt {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 13.243px;
    top: 16.077px;
    overflow: visible;
}

#Groupe_203_nu {
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_189_nv {
    fill: rgba(255, 255, 255, 1);
}

.Trac_189_nv {
    overflow: visible;
    position: absolute;
    width: 1.64px;
    height: 2.626px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_218_nw {
    position: absolute;
    width: 172.09px;
    height: 45.182px;
    left: 0px;
    top: 17.999px;
    overflow: visible;
}

#Groupe_207_nx {
    position: absolute;
    width: 33.78px;
    height: 34.656px;
    left: 0px;
    top: 1.197px;
    overflow: visible;
}

#Groupe_206_ny {
    position: absolute;
    width: 33.78px;
    height: 34.656px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_190_nz {
    fill: rgba(255, 255, 255, 1);
}

.Trac_190_nz {
    overflow: visible;
    position: absolute;
    width: 33.78px;
    height: 34.656px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_209_n {
    position: absolute;
    width: 22.065px;
    height: 28.772px;
    left: 39.066px;
    top: 16.409px;
    overflow: visible;
}

#Groupe_208_n {
    position: absolute;
    width: 22.065px;
    height: 28.772px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_191_n {
    fill: rgba(255, 255, 255, 1);
}

.Trac_191_n {
    overflow: visible;
    position: absolute;
    width: 22.065px;
    height: 28.772px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_211_n {
    position: absolute;
    width: 12.639px;
    height: 20.314px;
    left: 95.429px;
    top: 15.538px;
    overflow: visible;
}

#Groupe_210_n {
    position: absolute;
    width: 12.639px;
    height: 20.314px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_192_n {
    fill: rgba(255, 255, 255, 1);
}

.Trac_192_n {
    overflow: visible;
    position: absolute;
    width: 12.639px;
    height: 20.314px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_213_n {
    position: absolute;
    width: 5.884px;
    height: 29.142px;
    left: 113.125px;
    top: 6.71px;
    overflow: visible;
}

#Groupe_212_n {
    position: absolute;
    width: 5.884px;
    height: 29.142px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_193_n {
    fill: rgba(255, 255, 255, 1);
}

.Trac_193_n {
    overflow: visible;
    position: absolute;
    width: 5.884px;
    height: 29.142px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_215_n {
    position: absolute;
    width: 22.383px;
    height: 36.358px;
    left: 125.578px;
    top: 0px;
    overflow: visible;
}

#Groupe_214_oa {
    position: absolute;
    width: 22.383px;
    height: 36.358px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_194_ob {
    fill: rgba(255, 255, 255, 1);
}

.Trac_194_ob {
    overflow: visible;
    position: absolute;
    width: 22.383px;
    height: 36.358px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_217_oc {
    position: absolute;
    width: 18.706px;
    height: 20.27px;
    left: 153.384px;
    top: 16.409px;
    overflow: visible;
}

#Groupe_216_od {
    position: absolute;
    width: 18.706px;
    height: 20.27px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_195_oe {
    fill: rgba(255, 255, 255, 1);
}

.Trac_195_oe {
    overflow: visible;
    position: absolute;
    width: 18.706px;
    height: 20.27px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_220_of {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 73.877px;
    top: 6.439px;
    overflow: visible;
}

#Groupe_219_og {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_196_oh {
    fill: rgba(255, 255, 255, 1);
}

.Trac_196_oh {
    overflow: visible;
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_222_oi {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 81.01px;
    top: 6.439px;
    overflow: visible;
}

#Groupe_221_oj {
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_197_ok {
    fill: rgba(255, 255, 255, 1);
}

.Trac_197_ok {
    overflow: visible;
    position: absolute;
    width: 4.076px;
    height: 4.076px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Groupe_224_ol {
    position: absolute;
    width: 6.013px;
    height: 1.913px;
    left: 76.318px;
    top: 2.856px;
    overflow: visible;
}

#Groupe_223_om {
    position: absolute;
    width: 6.013px;
    height: 1.913px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Trac_198_on {
    fill: rgba(255, 255, 255, 1);
}

.Trac_198_on {
    overflow: visible;
    position: absolute;
    width: 6.013px;
    height: 1.913px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.responsivebanneres {
    display: block
}

.responsivebanneresD {
    display: none
}

.btnMCpte {
    top: 94px;
    left: 1355px;
    width: 180px;
    height: 59px;
    /* UI Properties */
    background: #69C0AC 0% 0% no-repeat padding-box;
    opacity: 1;
}

.fbLogo {
    top: 106px;
    left: 1545px;
    width: 32px;
    height: 32px;
    /* UI Properties */
    background: transparent url('../img/c-facebook.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    /* margin-left: 10px;
    margin-right: 10px;
    padding: 17px;*/
}

.instaLogo {
    top: 106px;
    left: 1587px;
    width: 33px;
    height: 33px;
    /* UI Properties */
    background: transparent url('../img/c-instagram.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    /* margin-right: 10px;
    padding: 17px;*/
}

.linkLogo {
    top: 106px;
    left: 1630px;
    width: 33px;
    height: 33px;
    /* UI Properties */
    background: transparent url('../img/c-linkedin.png') 0% 0% no-repeat padding-box;
    opacity: 1;
    /*margin-right: 10px;
    padding: 17px;*/
}

.offset-md-3 {
    margin-left: 16% !important;
}

@media(min-width: 375px) and (max-width: 667px) {
    .offset-md-3 {
        margin-left: -20% !important;
    }
    .btn-primary {
        padding: 0px 15px !important;
    }
    .btnMCpte {
        display: block !important;
    }
    .fbLogo {
        margin-left: 3px !important;
        margin-right: 0px !important;
    }
    .instaLogo {
        margin-right: 2px !important;
    }
    .linkLogo {
        margin-right: 0px !important;
    }
    /*.M_LogoAccueil {
        width: 107% !important;
    }*/
    .headerBtn {
        margin-left: 52px !important;
    }
}

@media(min-width: 414px) and (max-width: 896px) {
    .headerBtn {
        margin-left: 77px !important;
    }
    .offset-md-3 {
        margin-left: -19% !important;
    }
}

.flex-container {
    padding-left: 0px !important;
    margin: 0;
    list-style: none;
    display: flex;
}

.flex-center {
    justify-content: center;
}

.flex-start {
    justify-content: flex-start;
}

.flex-end {
    justify-content: flex-end;
}

.w-100 {
    width: 100% !important;
}

.accueil {
    text-align: center;
    color: #69c0ac;
   font-size: 40px;
    font-family: 'PoetsenOne';
}

.titleAdhesionE {
    font-family: 'PoetsenOne';
    color: #4f1d4a;
    font-size: 24px;
}


.titleAdhesionP {
    font-family: 'PoetsenOne';
    color: #F1AF02;
    font-size: 24px;
}

.btnAccueil {
    color: white !important;
     font-family: 'PoetsenOne';
    font-size: 24px;
    font-weight: 100 !important;
    padding-top: 20px;
    padding-bottom: 20px;
   /* width: 584px !important;*/
    text-align: left;
}

.modal-sm {
   /* max-width: 470px !important;*/
    width: 478px !important;
    height: 655px !important;
}

.nameEtab {
    color: #4f1d4a;
    font-weight: bold;
    font-size: 17px;
}
.nameEtabClient {
    color: #4f1d4a;
    font-weight: bold;
   font-family: 'PoetsenOne';
    font-size: 24px;
}

.M_IconEsapceClt{
    width: 30px;
    height: 30px;
}

.M_IconEsapceCltr{
    width: 25.68px;
    height: 30px;
}

.iconEspace{
    width: 30.82px;
    height: 36px;

}

.padding-00 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
/* Personnalisation du champ d'entrée */
input[bsdatepicker] {
    font-size: 16px;
    padding: 10px;
    border: 2px solid #007bff;
    border-radius: 5px;
  }
  
  /* Personnalisation de la boîte de dialogue du calendrier */
  .bs-datepicker {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Personnalisation des jours du calendrier */
  .bs-datepicker .bs-datepicker-body td {
    color: #333;
    padding: 10px;
    text-align: center;
  }
  
  /* Couleur pour les jours sélectionnés */
  .bs-datepicker .bs-datepicker-body td.selected {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Effet au survol des jours */
  .bs-datepicker .bs-datepicker-body td:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  /* Personnalisation des boutons dans le calendrier */
  .bs-datepicker .bs-datepicker-footer button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
  }
  
  .bs-datepicker .bs-datepicker-footer button:hover {
    background-color: #0056b3;
  }
  
  /* Personnalisation du sélecteur de mois/année */
  .bs-datepicker .bs-datepicker-nav select {
    border: 1px solid #007bff;
    border-radius: 5px;
    padding: 5px;
    background-color: #fff;
  }
  