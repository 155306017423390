.tabset-content{
  .tab-content{
    background: $col-primary;
    color: $col-w;
  }
  .nav-link{
    width: max-content;
    background: $col-secondary;
    color: $col-w;
    margin: 0 10px;
    margin-bottom: -1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: none;
    display: flex;
  }
  .nav-tabs .nav-item{
    .nav-link{
      &::before{
        height: 21px;
        width: 30px;
      }
    }

  }
  .nav-tabs .nav-item:nth-child(1){
    .nav-link{
      &::before{
        content: url("../images/icons/gallery.svg");
      }
    }
  }
  .nav-tabs .nav-item:nth-child(2){
    .nav-link{
      &::before{
        content: url("../images/icons/gallery.svg");
      }
    }
  }
  .nav-tabs .nav-item:nth-child(3){
    .nav-link{
      &::before{
        content: url("../images/icons/gallery.svg");
      }
    }
  }
  .nav-tabs .nav-item:nth-child(4){
    .nav-link{
      &::before{
        content: url("../images/icons/gallery.svg");
      }
    }
  }
  .nav-tabs .nav-item:nth-child(5){
    .nav-link{
      &::before{
        content: url("../images/icons/gallery.svg");
      }
    }
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: $col-primary;
    color: $col-w;
  }
}



