.btn-primary {
    background: $col-secondary;
    border: 2px solid $col-secondary;
    padding: 15px 25px;
    &:hover {
        background: $col-secondary-hover;
        color: $col-w;
        border: 2px solid $col-secondary-hover;
    }
}

.btn-primary-line {
    background: transparent;
    border: 2px solid $col-secondary;
    padding: 15px 25px;
    color: $col-secondary;
    &:hover {
        background: $col-secondary-hover;
        color: $col-w;
        border: 2px solid $col-secondary-hover;
    }
}

.btn-secondary {
    background: transparent;
    border: 2px solid $col-primary;
    padding: 15px 25px;
    color: $col-primary;
    font-weight: 500;
    &:hover {
        background: $col-primary;
        color: $col-w;
        border: 2px solid $col-primary;
    }
}

.btn-icon {
    // width: 40px;
    // height: 40px;
    background: transparent;
    border: none;
}

.btn-mobile {
    display: none;
}

.btn-hover-icon {
    width: 180px;
    transition: width 0.5s;
    &:hover {
        width: 220px;
        position: relative;
        &::before {
            content: url("../images/icons/add-user.svg");
            position: absolute;
            width: 20px;
            right: 20px;
            top: 11px;
        }
    }
}

.push-up {
    position: sticky;
    left: 85vw;
    bottom: 0px;
}

.btn-dropFilter {
    background: $col-w;
    padding: 3px 15px;
    border-radius: 0;
    color: $col-primary;
}