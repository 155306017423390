body {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}

.top-position {
    position: relative;
    top: -90px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.content-inner-pages {
    min-height: auto !important;
    /*957px*/
    background: $col-bg-gris;
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
}

.page-contact {
    .box-contact div {
        &:nth-child(1) {
            height: 90px;
        }
        &:nth-child(2) {
            font-size: 18px;
            min-height: 70px;
        }
        &:nth-child(3) {
            font-size: 15px;
        }
    }
}

.page-mentions {
    background-image: url("../images/img-statique-mentionlegales.png");
    .box-mentions-1 {
        padding-top: 50px;
    }
    .box-mentions-2 div {
        &:nth-child(1) {
            height: 50px;
        }
    }
}

.page-sommes {
    background-image: url("../images/img-qui-sommes-nous.png");
}

.page-rejoindre {
    background-image: url("../images/rejoindre.png");
}

.mt-form {
    margin-top: 270px !important;
}

.content-home-site {
    background: $col-bg-gris;
}

@media screen and (max-width: 599px) {
    /*.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }*/
    container-navigation {
        margin: 0 !important;
    }
    .page-contact,
    .page-mentions,
    .page-rejoindre,
    .page-sommes {
        background-image: none !important;
    }
    .content-inner-pages {
        padding-top: 0 !important;
        background-color: white !important;
    }
    .content-page {
        margin-top: 0 !important;
    }
}