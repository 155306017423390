@import url("color.css");
@media (min-width: 1920px) {
    .M_txtOffreBox {
        text-align: center;
    }
    .M_btn_SCMCPTE {
        display: none;
    }
}

.responsiveLogo {
    display: none !important;
}

@media (min-width: 992px) {
    .STop160 {
        left: -14px;
    }
    .M_txtOffreBox {
        text-align: center;
    }
}

@media (max-width: 1199px) {
    .M_ContTabs {
        margin-top: 60px;
    }
    .M_txtOffreBox {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .M_btn_SCMCPTE {
        display: none !important;
    }
}


/* Normal desktop :992px. */

@media (max-width: 991px) {
    .STop160 {
        top: 0px;
        position: relative
    }
    .M_txtOffreBox {
        text-align: center;
    }
    .btnMCpte {
        display: none;
    }
}


/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {
    .modal-content {
        width: 90%; /* S'adapte à la largeur de l'écran mobile */
        margin-top: 20px; /* Réduit l'espace en haut pour les petits écrans */
    }
    .M_CFiltre {
        font-size: 12px
    }
    .M_btnMenu {
        width: 160px;
    }
    .M_LogoImg {
        width: 250px;
        margin-left: 100px !important;
    }
    .M_BtnConnect {
        margin-left: 0;
        width: 85px;
        font-size: 12px;
        height: 28px;
    }
    .M_txtOffreBox {
        text-align: center;
    }
    .btnMCpte {
        display: none;
    }
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        padding-right: 0px !important;
        /*border-radius: 20%;*/
    }
}

@media (max-width: 831px) {
    .M_btn_avatar {
        padding-right: 15px;
    }
}

@media (max-width: 767px) {
    #imgOffre{
        display: none;
    }
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
} 
.padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}

.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}

.margin0Espace{	
	margin: 0px!important;
}

.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
    width:60%;
	position:absolute !important; 
    /*padding-bottom: 0px !important;
    bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}

.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;
    padding-bottom: 10px !important
}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .top-position {
        position: relative;
        top: -56px !important;
    }
    .listOffres {
        --bs-gutter-x: 3.5rem !important;
        --bs-gutter-y: 0 !important;
    }
    .textDescr {
        width: auto !important;
    }
    .slider-form {
        .text-slider-form {
            font-size: 13px !important;
            width: 70% !important;
        }
        .carousel-control-next,
        .carousel-control-prev {
            position: absolute !important;
            top: 198px !important;
            width: 2rem !important;
            height: 2rem !important;
        }
        .carousel-control-next {
            right: 20px !important;
        }
        .carousel-control-prev {
            left: 20px !important;
        }
    }
    .c-check__checkbox::before {
        width: 24px !important;
        height: 24px !important;
    }
    .c-check__checkbox::after {
        width: 16px !important;
        height: 16px !important;
        top: -8px!important;
        left: 8px!important;
    }
    .form-select,
    .form-control {
        font-size: 13px!important;
    }
    /* étendre la taille des images du carousel du form à 100% de la largeur de l'écran */
    .div-carousel-mobile {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .div-slide-form-mobile.slider-form {
        padding-bottom: 0 !important;
    }
    .page-rejoindre,
    .page-contact {
        background: none !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .text-slider-form {
        font-size: 13px!important;
        width: 70% !important;
    }
    .content-avantages {
        margin: 0px !important
    }
    .top-menu {
        padding-right: 22px;
        padding-left: 22px;
    }
    .margeTop1 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .textCardResponsive {
        /* width: 250px;
height: 150px;
margin-top: -40px;*/
        margin-left: 14px;
        /* width: 67%; */
        width: 94%;
        padding-top: 4rem !important;
    }
    .slideShowHome .carousel-control-next-icon,
    .slideShowHome .carousel-control-prev-icon {
        width: 2rem !important;
        height: 2rem !important;
        margin-top: 0px !important;
    }
    .slideShowHome .carousel-control-next-icon {
        margin-left: 13px;
    }
    .slideShowHome .carousel-control-prev-icon {
        margin-right: -22px;
    }
    .slideShowHome .carousel-control-next,
    .slideShowHome .carousel-control-prev {
        /*top: 359px !important;*/
        top: 40px !important;
    }
    .div-footer {
        justify-content: left !important;
        align-items: left !important;
        text-align: left !important;
        padding-left: 85px!important;
        padding-bottom: 15px !important;
    }
    .padding-30 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .padding-00 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .blocJus {
        order: 0 !important
    }
    .padding-0 {
        padding: 0px !important;
    }
    .margin-0 {
        margin: 0px !important;
    }
    .imgResponsive {
        height: 406px;
        width: 390px;
    }
    .imgWidth {
        width: 407px !important;
        margin-left: -20px !important;
    }
    .imgResponsiveFooter {
        height: 307px;
        width: 400px;
    }
    .textDescripResponsive {
        font-size: 13px;
    }
    .titleDescripRespon {
        text-align: end !important;
    }
    .titleDescripRespon1Et3 {
        text-align: start !important;
    }
    .logoFooterResponsive {
        height: 64.62px;
        width: 176px;
    }
    .txtFooter {
        font-size: 14px !important;
        /*max-height: 8em !important;*/
    }
    .container-navigation {
        margin: 0px !important;
        border: 2px solid var(--color_7) !important;
        padding: 10px !important;
    }
    .page-contact {
        padding: 30px!important;
    }
    .page-contact p {
        font-size: 13px!important;
    }
    .M_IconEsapceClt {
        width: 36px !important;
        height: 36px !important;
    }
    .M_TTcompte {
        font-size: 20px !important;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_TextR{
        padding: 0px !important;
        font-size: 19px !important;;
    }
    .M_TTcompte1,
    .M_TextSB {
        font-size: 14px !important;
        padding: 10px 0px 0px 30px !important;

    }
    .M_TTcompte1{
        position: absolute;
    }
    .M_btnCompteClient{float: none !important;}
    .M_grid {
        display: block !important;
        margin-top: 30px!important;
    }
}

@media (max-width: 375px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}

.margin0Espace{	
	margin: 0px!important;
}
 
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .title-secteur {
        font-size: 21px !important;
        text-align: justify;
    }
    .img-secteur {
        /* height: 28pc !important;*/
        height: 406.1px !important;
    }
    /*.padding-30 {
        padding-left: 10px;
        padding-right: 10px;
    }*/
    .btn-primary {
        padding: 5px 15px;
    }
    .btn-secondary {
        padding: 5px 15px;
    }
    .btnResponsiveJuridique {
        top: -422px;
    }
    .btnResponsiveRestau {
        /* padding-left: 0px !important;
        padding-right: 0px !important;*/
        top: -390px;
    }
    .btnResponsiveMedical {
        /*padding-left: 0px !important;
        padding-right: 0px !important;*/
        top: -322px;
    }
    .btnResponsiveBeaute {
        /* padding-left: 0px !important;
        padding-right: 0px !important;*/
        top: -351px;
    }
    .btnResponsiveTpePme {
        /* padding-left: 0px !important;
        padding-right: 0px !important;*/
        top: -449px;
    }
    .slideShowHome .carousel-control-next-icon,
    .slideShowHome .carousel-control-prev-icon {
        width: 2rem !important;
        height: 2rem !important;
        margin-top: 0px !important;
    }
    .slideShowHome .carousel-control-next-icon {
        margin-left: 13px;
    }
    .slideShowHome .carousel-control-prev-icon {
        margin-right: -22px;
    }
    .slideShowHome .carousel-control-next,
    .slideShowHome .carousel-control-prev {
        /*top: 359px !important;*/
        top: 40px !important;
    }
    .M_btn_avatar {
        padding-right: 0px;
    }
    .M_ConTopLogin {
        padding-left: 0px;
    }
    .M_TResponsive {
        font-size: 14px;
    }
    .btnMCpte {
        display: none;
    }
}

@media (max-width: 320px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
         padding-right:0px !important;
      text-align: left;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .M_ConTopLogin {
        padding-left: 0px;
    }
    .M_TextSB {
        margin-top: 15px;
        /*width: 100%!important; */
    }
  .M_btnCompte {
        float: left ;  
        padding-top: 35px;
        width: 100%;
    }
    .M_TTcompte {
        font-size: 18px !important;
    }
    .M_Command {
        margin-top: 45px;
        font-size: 17px;
    }
    .M_IconEsapceClt {
        width: 45px
    }
    .M_LAbonn {
        font-size: 14px;
    }
    .M_Icon {
        width: 25px;
    }
    .btnMCpte {
        display: none;
    }
}


/* small mobile :320px. */

@media (max-width: 767px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
   /* padding-bottom: 0px !important;
   bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .M_ContTabs {
        margin-top: 60px;
    }
    .M_Offre {
        top: 0px;
    }
    .M_Down {
        bottom: 30pc;
    }
    .btnMCpte {
        display: none;
    }
    .footerAccueil {
        text-align: center;
    }
    /*.M_LogoAccueil {
        
    }*/
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        padding-right: 0px !important;
    }
    .imgMedia {
        text-align: center;
    }
    .slidehomeImg {
        height: 406.1px !important;
        object-position: -163.96px;
        object-fit: none;
        height: 406.1px !important;
        object-position: -163.96px;
        object-fit: none;
    }
    .margeTop1 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media (max-width: 382px) {
.M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
#imgOffre{
    display: none;
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    /*padding-bottom: 0px !important;
    bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
   .modal-sm{
	width:388px !important;
	padding-left:20px !important;
	 padding-right: 20px !important;
   }

   .modal.show .modal-dialog {
        display: flex;
        align-items: center;
        height: 177vh !important;
        /*padding-left: 20px !important;
        padding-right: 20px !important;*/
    }
}

@media (max-width: 360px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    /*padding-bottom: 0px !important;
    bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .modal.show .modal-dialog {
        display: flex;
        align-items: center;
        height: 153vh !important;
        max-height: 153vh !important;
        /*padding-left: 20px !important;;
        padding-right: 20px !important;;*/
    }
    .modal-sm{
        width:388px !important;
        padding-left:20px !important;
         padding-right: 20px !important;
       }
    a {
        text-decoration: none;
        /* display: inline-block;*/
        padding: 0px 0px !important;
    }
}

@media (min-width: 382px) and (max-width: 524px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    /*padding-bottom: 0px !important;
    bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}

    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .modal.show .modal-dialog {
        display: flex;
        align-items: center;
        height: 135vh !important;
        max-height: 135vh !important;
        /*padding-left: 20px !important;;
        padding-right: 20px !important;;*/
    }
   /* textarea.form-control{
        max-width: 70% !important;
    }*/
    .modal-sm{
        width:388px !important;
        padding-left:20px !important;
         padding-right: 20px !important;
       }
    .M_Icon {
        width: 30px;
    }
    .M_IconHome {
        width: 30px;
    }
    .M_LogoHome {
        width: 81.63px;
        height: 29.97px;
        /*margin-top: 10px;*/
    }
    /*.M_LogoAccueil {
        width: 130%;
        height: 107%;
        margin-left: 65px !important;
        padding-top: 0px !important;
    }*/
    .M_header {
        width: 100%;
        height: 48px;
    }
    .M_DropMenu {
        top: 53px !important;
    }
    .btnMCpte {
        display: none;
    }
    .footerAccueil {
        text-align: center;
    }
    .modal-content {
        margin-top: 0px;
        margin-left: 0px;
    }
    a {
        text-decoration: none;
        /* display: inline-block;*/
        padding: 0px 0px !important;
    }
}

@media (max-width: 525px) {

#mobileMenu{
    display: block;
}
#imgOffre{
    display: none;
}
#webMenu{
    display: none;
}
.M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
   /* padding-bottom: 0px !important;
    bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 100%;
        height: 80px;
        margin-bottom: 25px;
    }
    .M_btn_LoginCode {
        font-size: 14px;
    }
    .M_LogoImg {
        width: 200px;
        margin-right: 100px !important;
    }
    .M_BtnConnect {
        margin-left: 0;
        width: 75px;
        font-size: 10px;
        height: 25px;
    }
    .M_IconHome {
        width: 25px;
    }
    .M_LogoHome {
        width: 81.63px;
        height: 29.97px;
    }
    .M_IconMenu {
        width: 26px !important;
        height: 39px !important;
        margin-top: 3px !important;
    }
    .M_TextSB {
        margin-top: 25px;
        width: 100% !important;
        padding-left: 10px !important;
    }
    .M_Command {
        margin-top: 45px;
        font-size: 17px;
    }
    .M_downUp {
        display: flex;
        font-size: 30px;
    }
    .M_Down {
        width: 40px;
        height: 40px;
    }
    .M_header {
        width: 100%;
        height: 48px;
        margin-bottom: 0px;
    }
    .pb-20{
        margin-bottom: 20px !important;
    }

	
	.M_Top , .M_Top_P{
	       padding-top:20px;
           margin-top:0px; 
            height: 110px !important;
	}
    .M_TabsC>ul>li {
        flex: 0 0 50% !important;
        max-width: 50% !important;
        margin-top: 2px;
    }
    .M_TOffre {
        font-size: 20px;
    }
    .M_TOffre2 {
        font-size: 13px;
    }
    .M_LogoOffre {
        width: 120px;
    }
    .M_btn_Renouv {
      margin-top:20px !important; 
      margin-bottom:20px !important; 
      font-size: 17px !important;
    }
    .modal-title {
        font-size: 20px;
        margin-right: 15px;
    }
    .M_btn_Renouv {
        height:93px !important;
        padding-top: 0px !important;
        width:100% !important
    }
    .M_labelResponsive {
        padding-left: 10px;
        padding-right: 10px;
    }
    .M_ConTopLogin {
        top: 15px;
    }
    /*.M_LogoAccueil {
        width: 130%;
        height: 107%;
        margin-left: 65px !important;
        padding-top: 0px !important;
    }*/
    .footerAccueil {
        text-align: center;
    }
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        padding-right: 0px !important;
    }
    .modal-content {
        margin-top: 0px;
        margin-left: 0px;
    }
}

@media (max-width: 1199px) {
    .M_Command {
        margin-top: 50px !important;
        font-size: 17px;
    }
    .M_Pref {
        margin-top: 50px !important;
    }
    .M_TextSB {
        margin-top: 25px;
    }
    .M_btnCompte {
        float: left ;  
        padding-top: 35px;
        width: 100%;
    }
    .M_Connect {
        margin-top: 0px !important;
    }
    /*.M_LogoAccueil {
        width: 130%;
        height: 125% !important;
        margin-left: 130px !important;
        padding-top: 0px !important;
    }*/
    .footerAccueil {
        text-align: center;
    }
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        /* padding-right: 0px !important; */
        padding-right: 175px !important;
        padding-left: 20px !important;
    }
    .responsiveLogo {
        display: block !important;
    }
    .d-flex {
        display: flex!important;
    }
    .headerBtn {
        display: none !important;
    }
}

@media (max-width: 400px) {

    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    /*bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .M_header {
        width: 100%;
        height: 48px;
        margin-bottom: 0px;
    }
	
 .M_btn_accueil {
   width: 298px!important;
   height: 58.82px !important;
   font-size:14px !important;
   margin-bottom:20px !important
}
.M_Top , .M_Top_P{
  padding-top:20px;
  margin-top:0px; 
   height: 110px !important;
	}
    .pb-20{
        margin-bottom: 20px !important;
    }

    .footerAccueil {
        text-align: center;
    }
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        padding-right: 77px !important;
    }
    .responsiveLogo {
        padding-left: 60px !important;
        display: block;
    }
    .d-flex {
        display: flex!important;
    }
    .headerBtn {
        display: none !important;
    }
}

@media (min-width: 700px) and (max-width: 767px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    /*bottom:39px !important;
    padding-bottom: 0px !important;*/

}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .M_loginS .below {
        width: 506px !important;
    }
    /*.M_LogoAccueil {
        width: 130%;
        height: 107%;
        margin-left: 65px !important;
        padding-top: 0px !important;
    }*/
    .footerAccueil {
        text-align: center;
    }
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        padding-right: 0px !important;
    }
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        /* max-width: 1060px !important;*/
    }
}


/* Large Mobile :480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    padding-bottom: 0px !important;
   /* bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .M_ContTabs {
        margin-top: 60px;
    }
    .M_Offre {
        top: 0px;
    }
    .M_CFiltre {
        margin: 10px;
    }
    .M_LogoImg {
        width: 100px;
        margin-left: -20px !important;
    }
    .M_BtnConnect {
        margin-left: 0;
        width: 75px;
        font-size: 10px;
        height: 28px;
    }
    .M_ConTopLogin {
        padding-left: 22px;
    }
    /*.M_LogoAccueil {
        width: 150px;
        margin-left: 50% !important;
        margin-right: 50% !important;
    }*/
    .M_btn_SCMCPTE {
        border: none;
        background-color: var(--color_1) !important;
        cursor: pointer;
        padding-right: 0px !important;
    }
    a {
        text-decoration: none;
        /* display: inline-block;*/
        padding: 0px 0px !important;
    }
}


/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {
    .modal-content {
        width: 90%; /* S'adapte à la largeur de l'écran mobile */
        margin-top: 20px; /* Réduit l'espace en haut pour les petits écrans */
    }
    #imgOffre{
        display: none;
    }
    .M_CFiltre {
        font-size: 12px
    }
    .M_btnMenu {
        width: 160px;
    }
    .M_LogoImg {
        width: 250px;
        margin-left: 100px !important;
    }
    .M_BtnConnect {
        margin-left: 0;
        width: 85px;
        font-size: 12px;
        height: 28px;
    }
}

@media (max-width: 831px) {
    .M_btn_avatar {
        padding-right: 15px !important;
    }
}

@media (max-width: 375px) {
    .M_hautBoxEs , .M_hautBox  {
     width: 254px !important
}
.radio-container .radio-label {
    font-size: 13px !important;
}
.accueil {
    text-align: left;
    font-size: 30px !important ;
}
.btnAccueil {
    font-size: 17px;
	padding-right:0px !important;
    text-align: left;
	
}
.spanbtnAccueil {
    width:60%;
	bottom:129px !important;
	position:absolute !important;
}
.padding0Espace{	
	padding-right:0px !important;
	padding-left:0px !important;
}
 .padding00Espace{	
	padding-top:0px !important;
	padding-bottom:0px !important;
}
.padding10Espace{	
	padding-top:10px !important;
	padding-bottom:0px !important;
}


.margin0Espace{	
	margin: 0px!important;
}
 
.spanbtnAccueil2 {
   width:60%;
	position:absolute !important;
    bottom:141px !important;
}
.spanbtnAccueil4 {
   width:60%;
	position:absolute !important;
    /*padding-bottom: 0px !important;
    bottom:39px !important;*/
}
.btnAccueil{
    /*padding-top: 0px !important;*/}
.spanbtnAccueil3{
    position: absolute;
    left: 48px !important;
    bottom: 121px;
}
.iconEspace, .M_IconEsapceClt {
    width: 30px;
    height: 30px;padding-bottom:10px !important
}.M_hautBox img {
    padding: 0px;
}.M_IconEsapceCltr{margin-right:10px}
.titleAdhesionE {
 
    font-size: 17px;
    padding-top: 20px;
}
    .imgResp{
        width: 388px;
        height: 80px;
        margin-bottom: 25px;
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_ConTopLogin {
        padding-left: 0px;
    }
    .M_TResponsive {
        font-size: 14px;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 342px;/* Permet à l'image de s'adapter dynamiquement */
        background-size: contain !important; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat !important; /* Empêche la répétition de l'image */
    }
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    
    .title-secteur {
        font-size: 22px !important;
        text-align: justify;
    }
    .btnResponsiveJuridique .btnResponsiveRestau .btnResponsiveMedical .btnResponsiveBeaute .btnResponsiveTpePme {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .blocJus {
        order: 0 !important
    }
}

@media (max-width: 320px) {
    .blocJus {
        order: 0 !important
    }
    .slideShowHome .carousel-control-next-icon,
    .slideShowHome .carousel-control-prev-icon {
        width: 2rem !important;
        height: 2rem !important;
        margin-top: 0px !important;
    }
    .slideShowHome .carousel-control-next-icon {
        margin-left: 13px;
    }
    .slideShowHome .carousel-control-prev-icon {
        margin-right: -22px;
    }
    .slideShowHome .carousel-control-next,
    .slideShowHome .carousel-control-prev {
        /* top: 359px !important; */
        top: 40px !important;
    }
    .M_ConTopLogin {
        padding-left: 0px;
    }
    .M_TextSB {
        margin-top: 15px;
    }
    .M_btnCompte {
        float: left ;  
        padding-top: 35px;
        width: 100%;
    }
    .M_TTcompte {
        font-size: 19px;
    }
    .M_Command {
        margin-top: 45px;
        font-size: 17px;
    }
    .M_IconEsapceClt {
        width: 45px
    }
    .M_LAbonn {
        font-size: 14px;
    }
    .M_Icon {
        width: 25px;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* small mobile :320px. */

@media (max-width: 767px) {
    .M_ContTabs {
        margin-top: 60px;
    }
    .M_Offre {
        top: 0px;
    }
    .M_Down {
        bottom: 4pc;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media (min-width: 382px) and (max-width: 524px) {
    .slideShowHome .carousel-control-next-icon,
    .slideShowHome .carousel-control-prev-icon {
        width: 2rem !important;
        height: 2rem !important;
        margin-top: 0px !important;
    }
    .slideShowHome .carousel-control-next-icon {
        margin-left: 13px;
    }
    .slideShowHome .carousel-control-prev-icon {
        margin-right: -22px;
    }
    .slideShowHome .carousel-control-next,
    .slideShowHome .carousel-control-prev {
        /* top: 359px !important; */
        top: 40px !important;
    }
    .M_Icon {
        width: 30px;
    }
    .M_IconHome {
        width: 30px;
    }
    .M_LogoHome {
        width: 81.63px;
        height: 29.97px;
    }
    .M_header {
        width: 100%;
        height: 48px;
    }
    .M_DropMenu {
        top: 53px !important;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    a {
        text-decoration: none;
        /* display: inline-block;*/
        padding: 0px 0px !important;
    }
}

@media (max-width: 525px) {
    .M_btn_LoginCode {
        font-size: 14px;
    }
    .M_LogoImg {
        width: 200px;
        margin-right: 100px !important;
    }
    .M_BtnConnect {
        margin-left: 0;
        width: 75px;
        font-size: 10px;
        height: 25px;
    }
    .M_IconHome {
        width: 25px;
    }
    .M_LogoHome {
        width: 81.63px;
        height: 29.97px;
    }
    .M_IconMenu {
        width: 26px !important;
        height: 39px !important;
        margin-top: 3px !important;
    }
    .M_TextSB {
        margin-top: 25px;
    }
    .M_Command {
        margin-top: 45px;
        font-size: 17px;
    }
    .M_downUp {
        display: flex;
        font-size: 30px;
    }
    .M_Down {
        width: 40px;
        height: 40px;
    }
    .M_header {
        width: 100%;
        height: 48px;
        margin-bottom: 0px;
    }
	
	.M_btn_accueil {
   width:298px !important;
   height: 58.82px !important;
   font-size:14px !important;
   padding-top:10px !important;
   margin-bottom:20px !important
}
.M_Top , .M_Top_P{
  padding-top:20px;margin-top:0px;  height: 110px;
	}
    .pb-20{
        margin-bottom: 20px !important;
    }
	

    .M_TabsC>ul>li {
        flex: 0 0 50% !important;
        max-width: 50% !important;
        margin-top: 2px;
    }
    .M_TOffre {
        font-size: 20px;
    }
    .M_TOffre2 {
        font-size: 13px;
    }
    .M_LogoOffre {
        width: 120px;
    }
    .M_btn_Renouv {
      margin-top:20px !important; 
      margin-bottom:20px !important; 
      font-size: 17px !important;
      padding-top: 0px !important;
    }
    .modal-title {
        font-size: 20px;
        margin-right: 15px;
    }
    .M_btn_Renouv {
        height:93px !important;
         width:100% !important
    }
    .M_labelResponsive {
        padding-left: 10px;
        padding-right: 10px;
    }
    .M_ConTopLogin {
        top: 30px;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    .slider-form {
        max-width: 100% !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
}

@media (max-width: 1199px) {
    .M_Command {
        margin-top: 50px !important;
        font-size: 17px;
    }
    .M_Pref {
        margin-top: 50px !important;
    }
    .M_TextSB {
        margin-top: 25px;
    }
    .M_btnCompte {
        float: left ;  
        padding-top: 35px;
        width: 100%;
    }
    .M_TTcompteClient{
        font-size:24px
    }
    .M_Connect {
        margin-top: 0px !important;
    }
}

@media (max-width: 400px) {
    .M_header {
        width: 100%;
        height: 48px;
        margin-bottom: 0px;
    }
	
	 .M_btn_accueil {
    width: 298px !important;
    height: 58.82px !important;
    font-size:14px !important;
    padding-top:10px !important;
    margin-bottom:20px !important
}
.M_Top , .M_Top_P{
  padding-top:20px;margin-top:0px;  height: 110px;
	}
    .pb-20{
        margin-bottom: 20px !important;
    }
    .M_InputLoginClient{
        width: 160px !important;
        height: 47px !important;
    }
	
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    .slider-form {
        max-width: 100% !important;
    }
}

@media (min-width: 700px) and (max-width: 767px) {
    .M_hautBoxEs , .M_hautBox {
        width: 254px !important
    }
    .M_hautBox {
        width: 254px !important
    }
    .M_loginS .below {
        width: 506px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_btn_CAv_Client{
        padding: 17px 20px !important;
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
}


/* Large Mobile :480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .M_ContTabs {
        margin-top: 60px;
    }
    .M_Offre {
        top: 0px;
    }
    .M_CFiltre {
        margin: 10px;
    }
    .M_LogoImg {
        width: 100px;
        margin-left: -20px !important;
    }
    .M_BtnConnect {
        margin-left: 0;
        width: 75px;
        font-size: 10px;
        height: 28px;
    }
    .M_ConTopLogin {
        padding-left: 22px;
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        /*max-width: 1060px;*/
    }
}


/* ----------- iPhone 4 and 4S ----------- */


/* Portrait and Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
   /* .img-bg-dialog{
        height:50% !important;
    }*/
    .radio-container .radio-label{
       font-size: 13px !important;
        
    }
}


/* Portrait */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */


/* Portrait and Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .M_ContentOffre {
        display: none;
    }
    #imgOffre{
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Portrait */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */


/* Portrait and Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    a {
        text-decoration: none;
        /* display: inline-block;*/
        padding: 0px 0px !important;
    }
}


/* Portrait */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
    .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .blocJus {
        order: 0 !important
    }
}


/* Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .blocJus {
        order: 0 !important
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */


/* Portrait and Landscape */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    .slideShowHome .carousel-control-next-icon,
    .slideShowHome .carousel-control-prev-icon {
        width: 2rem !important;
        height: 2rem !important;
        margin-top: 0px !important;
    }
    .slideShowHome .carousel-control-next-icon {
        margin-left: 13px;
    }
    .slideShowHome .carousel-control-prev-icon {
        margin-right: -22px;
    }
    .responsiveLogo {
        display: block;
    }
    .slideShowHome .carousel-control-next,
    .slideShowHome .carousel-control-prev {
        /* top: 359px !important;
        top: 359px !important; */
        top: 40px !important;
    }
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Portrait */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Landscape */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* ----------- iPhone X ----------- */


/* Portrait and Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Portrait */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


/* Landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .M_ContentOffre {
        display: none;
    }
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
    .M_ContentCompte {
        margin-bottom: 25px;
        height: 120px !important;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .nameClient {
        display: none
    }
    .M_btn_avatar {
        padding-right: 15px !important;
    }
    .M_OfContainer {
        top: 0px !important;
        padding-top: 0px !important;
    }
        .modal-dialog {
        margin: auto !important;
    }
    .modal-sm{
        width: 100% !important;
    
    }
    .M_SliderHome {
        height: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media screen and (max-width: 599px) {
    .M_cardColor {
        right: -7px !important;
        top: 1px !important;
        width: 70px;
        height: 50px;
    }
    .responsivebanneres {
        display: none
    }
    .responsivebanneresD {
        display: block
    }
}

@media screen and (max-width: 900px) {
    .slidehomeImg {
        height: unset !important;
        width: 100% !important;
        object-fit: contain;
        object-position: unset;
    }
}
/* Pour les écrans de 6,1 pouces (iPhone 13) */
@media only screen and (max-width: 375px) {
    .M_ImgHome {
        width: 100%; /* Garde la largeur du conteneur */
        height: 187px; /* Permet à l'image de s'adapter dynamiquement */
        background-size: contain; /* Affiche l'image entière sans la couper */
        background-repeat: no-repeat; /* Empêche la répétition de l'image */
    }
}

@media (max-width: 1366px) and (min-height: 768px) {
 
    .modal-content {
        width: 800px; /* Ajuste la largeur du modal */
        margin: 50px auto; /* Centre avec des marges adaptées */
    }
}
