.content-menu{
  position: sticky;
  top: 0;
  z-index: 2;
  .top-menu{
    height: 50px;
    background: $col-primary;
    color: $col-w;
  }
  .menu-nav{
    background: $col-w;
  }
}

.content-menu-site{
  background: $col-w;
  height: 145px;
  position: sticky;
  top: 0;
  z-index: 9999;
}

/*****************************************************************/
/**
 * My CSS
 */

content-links {
  width: 1060px !important;
  height: 21px !important;
}
