.title-secteur {
    font-family: 'PoetsenOne', sans-serif;
    font-size: 2.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 40px;
}

.title-secteur-new {
    top: 0px;
    left: 0px;
    width: 176px;
    height: 48px;
    /* UI Properties */
    text-align: left;
    /*font: normal normal normal 40px/48px 'PoetsenOne';*/
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 48px;
    font-family: 'PoetsenOne';
    letter-spacing: 0px;
    color: #4F1D4A;
}

.color-primary {
    color: $col-primary;
}

.color-secondary {
    color: $col-secondary;
}

.bg-col-primary {
    background: $col-primary;
    color: $col-w;
}

.bg-col-secondary {
    background: $col-secondary;
    color: $col-w;
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
}

@media screen and (max-width: 599px) {
    .title-secteur {
        font-size: 24px !important;
        text-align: justify;
    }
    .title-secteur-new {
        font-size: 21px !important;
        text-align: justify;
    }
    .desc-mytribu-mobile {
        font-size: 13px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}